.promo-bar {
    height: var(--promo-bar--height);
    ul {
        height: var(--promo-bar--height);
        font-weight: $font-weight-bold;
        margin: 0;
        padding: 0;
        .list-inline-item {
            margin: 0;
            padding: 0;
            &:not(:last-child) {
                margin-right: 0;
            }
        }
        @include media-breakpoint-up(lg) {
            padding-top: 5px;
        }
    }
}
