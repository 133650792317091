// barlow-condensed-500 - latin-ext_latin
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/barlow-condensed/barlow-condensed-v11-latin-ext_latin-500.eot'); // IE9 Compat Modes
    src: local(''),
         url('../../fonts/barlow-condensed/barlow-condensed-v11-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), // IE6-IE8
         url('../../fonts/barlow-condensed/barlow-condensed-v11-latin-ext_latin-500.woff2') format('woff2'), // Super Modern Browsers
         url('../../fonts/barlow-condensed/barlow-condensed-v11-latin-ext_latin-500.woff') format('woff'), // Modern Browsers
         url('../../fonts/barlow-condensed/barlow-condensed-v11-latin-ext_latin-500.ttf') format('truetype'), // Safari, Android, iOS
         url('../../fonts/barlow-condensed/barlow-condensed-v11-latin-ext_latin-500.svg#BarlowCondensed') format('svg'); // Legacy iOS
    }

// open-sans-regular - latin
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/open-sans/open-sans-v28-latin-regular.eot'); // IE9 Compat Modes
    src: local(''),
         url('../../fonts/open-sans/open-sans-v28-latin-regular.eot?#iefix') format('embedded-opentype'), // IE6-IE8
         url('../../fonts/open-sans/open-sans-v28-latin-regular.woff2') format('woff2'), // Super Modern Browsers
         url('../../fonts/open-sans/open-sans-v28-latin-regular.woff') format('woff'), // Modern Browsers
         url('../../fonts/open-sans/open-sans-v28-latin-regular.ttf') format('truetype'), // Safari, Android, iOS
         url('../../fonts/open-sans/open-sans-v28-latin-regular.svg#OpenSans') format('svg'); // Legacy iOS
}

// open-sans-600 - latin
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/open-sans/open-sans-v28-latin-600.eot'); // IE9 Compat Modes
    src: local(''),
         url('../../fonts/open-sans/open-sans-v28-latin-600.eot?#iefix') format('embedded-opentype'), // IE6-IE8
         url('../../fonts/open-sans/open-sans-v28-latin-600.woff2') format('woff2'), // Super Modern Browsers
         url('../../fonts/open-sans/open-sans-v28-latin-600.woff') format('woff'), // Modern Browsers
         url('../../fonts/open-sans/open-sans-v28-latin-600.ttf') format('truetype'), // Safari, Android, iOS
         url('../../fonts/open-sans/open-sans-v28-latin-600.svg#OpenSans') format('svg'); // Legacy iOS
}

// Plantin Light
@font-face {
    font-family:"Plantin MT W01 Light";
    src: url("../../fonts/plantin/font.woff2") format("woff2"), 
    url("../../fonts/plantin/font.woff") format("woff");
}

