.hide-border {
    border-color: transparent;
    background-clip: border-box;
}

.form-check {
    clear: both;
    display: flex;
    gap: 0.5rem;
    .form-check-input {
        width: 1.4rem;
        height: 1.4rem;
        flex: 0 0 auto;
        &:checked {
            background-color: var(--theme--btn--primary--bg-color--hover);
            border-color: var(--theme--btn--primary--bg-color--hover);
        }
        &[type="checkbox"] {
            border-radius: 0;
        }
    }
    .form-check-label {
        width: 100%;
        flex: 0 1 auto;
        color: var(--theme--color);
        padding-top: 0.2rem;
    }
}

.form-label {
    font-family: $alt-headings-font-family;
    font-weight: $alt-headings-font-weight;
    line-height: $alt-headings-line-height;
    font-size: 1.2rem;
}

.field-validation-error {
    display: block;
    color: #b21917;
    font-size: .875em;
    margin-top: .25rem;
    width: 100%;
}

.invalid-feedback {
    display: block;

    &.field-validation-valid {
        display: none;
    }
}
