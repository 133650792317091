// product page elements

.product-gallery {
    --gap: 10px;
    --li-gap: 10px;
    --border-color: #{rgba($brand--slate,0.2)};
    display: flex;
    max-width: 300px;
    flex-direction: column;
    width: 100%;
    gap: var(--gap);
    position: sticky;
    top: var(--edge-padding);
    @include media-breakpoint-up(lg) {
        max-width: none;
    }
    figure {
        margin: 0;
        a {
            display: block;
            position: relative;
            &:after {
                display: block;
                content: '';
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid var(--border-color);
                pointer-events: none;
            }
            &.active {
                --border-color: #{$brand--slate};
            }
        }
    }
    &--badge {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        top: -5px;
        right: -5px;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        padding: 2px;
        margin: 0;
        background-color: $brand--vivid-pink;
        color: $brand--white;
        text-transform: uppercase;
        text-align: center;
        font-size: 16px;
        font-family: $alt-headings-font-family;
        font-weight: $alt-headings-font-weight;
        line-height: $alt-headings-line-height;
        pointer-events: none;
    }
    &--main-image {
        appearance: none;
        display: block;
        width: 100%;
        border: 0;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: zoom-in !important;
        aspect-ratio: 1;
    }
    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: var(--li-gap);
        li {
            display: block;
            margin: 0;
            padding: 0;
            width: calc((100% - (3 * var(--li-gap))) / 4); // 4 per row
            max-width: 80px;
            aspect-ratio: 1;
        }
    }
    &.enabled {
        &--main-image {
            cursor: pointer;
        }
    }
}

.product-gallery-carousel {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    position: sticky;
    top: var(--edge-padding);
    @include media-breakpoint-up(lg) {
        max-width: none;
    }
    &--badge {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        top: -5px;
        right: -5px;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        padding: 2px;
        margin: 0;
        background-color: $brand--vivid-pink;
        color: $brand--white;
        text-transform: uppercase;
        text-align: center;
        font-size: 16px;
        font-family: $alt-headings-font-family;
        font-weight: $alt-headings-font-weight;
        line-height: $alt-headings-line-height;
        pointer-events: none;
    }
    .swiper-pagination {
        position: relative;
        bottom: auto;
        left: auto;
    }
}

.product-purchase {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 30px;
    &--row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        &:first-child {
            margin-right: auto;
        }
        &:last-child {
            flex: 1 1 auto;
            @include media-breakpoint-up(sm) {
                flex: 0 0 auto;
            }
        }
        p {
            margin: 0;
        }
        .btn-theme.btn-primary .fa-plus {
            transform: translateY(0.1em);
        }
        .product-purchase--price {
            font-weight: $font-weight-bold;
            .product-purchase--price--prev {
                font-weight: $font-weight-base;
                text-decoration: line-through;
                opacity: 0.8;
                margin-right: 0.5em;
            }
        }
    }
}

// membership purchase options

.radio-button-panel-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .radio-button-panel-list--option {
        flex: 0 1 auto;
        label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2px;
            padding: var(--panel--padding);
            background-color: $color--lightest-grey;
            text-align: center;
            cursor: pointer;
            will-change: transform;
            transition: background-color 200ms linear, color 200ms linear;
            .radio-button-panel-list--option--icons {
                font-size: 1.1rem;
                white-space: nowrap;
                svg:not(:first-child) {
                    margin-left: 0.1em;
                    &.optional-member {
                        opacity: 0.5;
                    }
                }
            }
            .radio-button-panel-list--option--label {
                font-family: $alt-headings-font-family;
                font-weight: $alt-headings-font-weight;
                line-height: $alt-headings-line-height;
                font-size: 1.4rem;
                text-transform: uppercase;
                white-space: nowrap;
            }
            .radio-button-panel-list--option--info {
                font-size: 0.875rem;
                opacity: .8;
            }
            .radio-button-panel-list--option--price {
                font-weight: $font-weight-bold;
            }
        }
        input[type="radio"]:not(:checked) + label {
            &:hover, &:focus {
                background-color: var(--theme--btn--primary--bg-color--hover);
                color: var(--theme--btn--primary--color--hover);
            }
        }
        input[type="radio"]:checked + label {
            background-color: $brand--slate;
            color: white;
        }
    }
}

// booking inline summary panel

.booking-inline-summary-panel {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    & > div {
        width: 100%;
    }
    p {
        margin: 0;
    }
}

// basket listing

.basket-listing {
    display: flex;
    flex-direction: column;
    border-top: 1px var(--theme--rule--color) solid;
    & > li {
        padding: 20px 0;
        border-bottom: 1px var(--theme--rule--color) solid;
    }
    &--item {
        display: flex;
        flex-direction: row;
        gap: 10px;
        @include media-breakpoint-up(md) {
            gap: 15px;
        }
        &--image {
            width: 80px;
            flex: 0 0 auto;
            aspect-ratio: 1;
            @include media-breakpoint-up(md) {
                width: 100px;
            }
        }
        &--details {
            flex: 1 1 auto;
        }
        &--price-and-remove {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: space-between;
            margin-left: auto;
            p {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                white-space: nowrap;
            }
        }
        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            gap: 10px;
            .basket-listing--item--details {
                width: 100%;
                flex: 0 0 auto;
            }
            &.basket-listing--item--with-image {
                .basket-listing--item--image {
                    order: 2;
                }
                .basket-listing--item--details {
                    order: 1;
                }
                .basket-listing--item--price-and-remove {
                    order: 3;
                }
            }
            &:not(.basket-listing--item--with-image) {
                .basket-listing--item--price-and-remove {
                    width: 100%;
                    flex-direction: row;
                    align-items: flex-end;
                    margin-left: 0;
                    p:first-child {
                        align-items: flex-start;
                    }
                }
            }
        }
    }
    &--group {
        display: flex;
        flex-direction: column;
        gap: 15px;
        border-left: 2px solid $brand--moss;
        padding-left: 10px;
        &--title {
            color: $brand--moss;
            text-transform: uppercase;
        }
        .basket-listing--item {
            position: relative;
            &:before {
                display: block;
                content: '';
                width: 5px;
                height: 2px;
                background-color: $brand--moss;
                position: absolute;
                top: 0;
                left: -10px;
                @include media-breakpoint-down(sm) {
                    top: 10px;
                }
            }
        }
        @include media-breakpoint-up(md) {
            padding-left: 15px;
            .basket-listing--item:before {
                left: -15px;
            }
        }
    }
    &--group-remove {
        margin-top: 10px;
        width: 100%;
        p {
            text-align: right;
        }
    }
}

// sticky basket panel

.order-summary-column {
    @include media-breakpoint-up(md) {
        position: relative;
        #order-summary {
            position: sticky;
            top: 30px;
        }
    }
}

.order-group-label {
    color: $brand--moss;
    text-transform: uppercase;
}

// ******************************
// *****  scroll indicator  *****
// ******************************

@keyframes scroll-indicator-move {
    0% {
        transform: translate3d(0,0,0);
    }
    50% {
        transform: translate3d(0,-25%,0);
    }
    100% {
        transform: translate3d(0,0,0);
    }
}

.scroll-indicator {
    &.scroll-indicator-enabled {
        position: relative;
        &.scroll-indicator-active {
            &:before, &:after {
                display: block;
                flex: 0 0 auto;
                box-sizing: border-box;
                position: sticky;
                pointer-events: none;
            }
            &:before {
                top: calc(50% - 40px);
                left: calc(50% - 30px);
                content: 'scroll';
                width: 60px;
                height: 80px;
                margin-bottom: -80px;
                background-color: rgba(black, 0.6);
                backdrop-filter: blur(3px);
                border-radius: 10px;
                color: white;
                z-index: 10;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                padding-top: 10px;
    
            }
            &:after {
                bottom: calc(50% - 30px);
                left: calc(50% - 8px);
                content: '';
                width: 16px;
                height: 30px;
                margin-top: -30px;
                background: transparent url('../../img/scroll-indicator.svg') no-repeat;
                background-size: contain;
                z-index: 11;
                animation: scroll-indicator-move 2s infinite ease-in-out;
            }
        }
    }
}

// *********************************
// *****  form number control  *****
// *********************************

.form-number-control {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    button {
        user-select: none;
        display: flex;
        appearance: none;
        width: 1.8rem;
        height: 1.8rem;
        border: 0;
        padding: 0;
        border-radius: 3rem;
        justify-content: center;
        align-items: center;
        background-color: var(--theme--btn--secondary--bg-color);
        color: var(--theme--btn--secondary--color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--secondary--bg-color--hover);
                color: var(--theme--btn--secondary--color--hover);
            }
        }
    }
    input[type="text"] {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        text-align: center;
    }
}

// **************************
// *****  data-wrapper  *****
// **************************

@keyframes data-wrapper--icon-animation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.data-wrapper {
    position: relative;
    width: 100%;
    &:before, &:after {
        display: block;
        content: '';
        pointer-events: none;
        position: absolute;
        opacity: 0;
        transition: opacity 250ms linear;
    }
    &:before {
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.4);
    }
    &:after {
        z-index: 11;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        margin: -25px 0 0 -25px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath style='fill:%23ffffff' d='M464 16c-17.67 0-32 14.31-32 32v74.09C392.1 66.52 327.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.89 5.5 34.88-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c50.5 0 96.26 24.55 124.4 64H336c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32V48C496 30.31 481.7 16 464 16zM441.8 289.6c-16.92-5.438-34.88 3.812-40.3 20.59C381.1 373.5 322.6 416 256 416c-50.5 0-96.25-24.55-124.4-64H176c17.67 0 32-14.31 32-32s-14.33-32-32-32h-128c-17.67 0-32 14.31-32 32v144c0 17.69 14.33 32 32 32s32-14.31 32-32v-74.09C119.9 445.5 184.6 480 255.1 480c94.45 0 177.4-60.34 206.4-150.2C467.9 313 458.6 294.1 441.8 289.6z'/%3E%3C/svg%3E");
        background-size: 100% 100%;
    }
    &.loading {
        min-height: 100px;
        pointer-events: none;
        user-select: none;
        &:before, &:after {
            opacity: 1;
        }
        &:after {
            animation: data-wrapper--icon-animation 3s infinite linear;
        }
    }
}

// ****************************************
// *****  visitors quantity selector  *****
// ****************************************

$visitors-quantity-selector--type--bg-color: $color--lightest-green;
$visitors-quantity-selector--type--color: $brand--slate;
$visitors-quantity-selector--type--label--font-family: $alt-headings-font-family;
$visitors-quantity-selector--type--label--text-transform: none;
$visitors-quantity-selector--type--label--font-weight: $alt-headings-font-weight;
$visitors-quantity-selector--type--label--font-size: 1.4rem;
$visitors-quantity-selector--type--label--line-height: 1.8rem;
$visitors-quantity-selector--type--label--color: $brand--slate;
$visitors-quantity-selector--type--strike-through--color: $brand--green;
$visitors-quantity-selector--type--btn--color: $brand--green;
$visitors-quantity-selector--type--btn--color-hover: $brand--slate;

.visitors-quantity-selector {
    display: flex;
    flex-direction: column;
    gap: $ecommerce-spacer;
    margin-bottom: $ecommerce-spacer;
    //max-height: $ecommerce-scroll-limits--fallback;
    //max-height: $ecommerce-scroll-limits--clamp;
    //overflow-y: auto;
    //-webkit-overflow-scrolling: touch;
    &[data-width-watcher] {
        opacity: 0;
        &.width-watched {
            opacity: 1;
        }
    }
    .expander {
        border: 0;
    }
    &::-webkit-scrollbar {
        appearance: none;
        width: 12px;
        background-color: rgba(0, 0, 0, 0.5);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        box-sizing: border-box;
        background-clip: content-box;
        border: 2px solid transparent;
        background-color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        &:hover,
        &:active {
            background-color: rgba(255, 255, 255, 0.8);
        }
    }
    &--visitor-type {
        width: 100%;
        background-color: $visitors-quantity-selector--type--bg-color;
        color: $visitors-quantity-selector--type--color;
        padding: var(--panel--padding);
        &--inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            label {
                min-width: 100%;
                margin: 0;
                strong {
                    font-family: $visitors-quantity-selector--type--label--font-family;
                    font-weight: $visitors-quantity-selector--type--label--font-weight;
                    text-transform: $visitors-quantity-selector--type--label--text-transform;
                    font-size: $visitors-quantity-selector--type--label--font-size;
                    line-height: $visitors-quantity-selector--type--label--line-height;
                    color: $visitors-quantity-selector--type--label--color;
                }
            }
            .visitors-quantity-selector--visitor-type--info {
                min-width: 100%;
                margin: 5px 0;
            }
            .visitors-quantity-selector--visitor-type--pricing {
                margin: 5px auto 0 0;
                flex: 0 1 auto;
                p {
                    margin: 0;
                    .strike-through {
                        display: inline-block;
                        position: relative;
                        &:before {
                            display: block;
                            content: "";
                            position: absolute;
                            z-index: 2;
                            top: 50%;
                            left: 50%;
                            margin-top: -1;
                            height: $ecommerce-spacer;
                            width: 110%;
                            background-color: $visitors-quantity-selector--type--strike-through--color;
                            transform: translateX(-50%) rotate(-8deg);
                        }
                    }
                }
            }
            .form-number-control {
                flex: 0 0 auto;
                margin: 5px 0 0 auto;
                flex: 0 1 auto;
                .btn-link {
                    color: $visitors-quantity-selector--type--btn--color;
                    &:not(.disabled, :disabled) {
                        &:hover,
                        &:focus {
                            color: $visitors-quantity-selector--type--btn--color-hover;
                        }
                    }
                }
                input.form-control.is-valid, input.form-control.is-invalid, .was-validated {
                    padding-right: .75rem;
                    background-image: none;
                }
                input.form-control[readonly] {
                    background-color: $brand--white;
                    cursor: not-allowed;
                }
            }
        }
        .visitors-quantity-selector--visitor-type--validation {
            .visitors-quantity-selector--visitor-type--validation--content {
                padding-top: 15px;
                .validation-item {
                    margin-top: 20px;
                }
            }
        }
    }
    &.wide {
        .visitors-quantity-selector--visitor-type {
            &--inner {
                flex: 1 1 auto;
                justify-content: space-between;
                align-items: center;
                .visitors-quantity-selector--visitor-type--pricing {
                    flex: 1 1 auto;
                }
                .form-number-control {
                    margin: 0;
                }
            }
        }
    }
}

#visitors-quantity-selector--tabs {
    gap: 5px;
    border-bottom: 2px solid $brand--slate;
    flex-wrap: nowrap;
    .btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background: $brand--green;
        color: $brand--white;
        padding: calc(#{$input-btn-padding-y} + (2 * #{$btn-border-width})) 5px;
        &.active {
            background: $brand--slate;
            color: $brand--white;
        }
        &:not(.active) {
            &:hover, &:focus {
                background: $brand--vivid-pink;
            }
        }
    }
    @include media-breakpoint-up(sm) {
        gap: 10px;
        .btn {
            padding: calc(#{$input-btn-padding-y} + (2 * #{$btn-border-width}))  calc(#{$btn-padding-x} + (2 * #{$btn-border-width}));
        }
    }
}


// *********************************
// *****  monthly date picker  *****
// *********************************

$monthly-date-picker--bg-color: transparent;
$monthly-date-picker--color: $body-color;
$monthly-date-picker--day--bg-color: $color--lightest-green;
$monthly-date-picker--day--color: $brand--slate;
$monthly-date-picker--day--bg-color--hover: $brand--vivid-pink;
$monthly-date-picker--day--color--hover: $brand--white;
$monthly-date-picker--day--unavailable--bg-color: $color--lightest-green;
$monthly-date-picker--day--unavailable--color: rgba($brand--green, 0.3);
$monthly-date-picker--day--today--bg-color: darken($color--lightest-green, 5%);
$monthly-date-picker--day--today--color: $brand--slate;
$monthly-date-picker--day--selected--bg-color: $brand--slate;
$monthly-date-picker--day--selected--color: $brand--white;
$monthly-date-picker--day--selected--icon-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Cpath fill='%23d8d027' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z' /%3E%3C/svg%3E");
$monthly-date-picker--day--sold-out--color: $brand--red;
$monthly-date-picker--day--indicator-a--color: $brand--orange;
$monthly-date-picker--day--indicator-b--color: $brand--light-green;
$monthly-date-picker--day--indicator-c--color: $brand--baby-blue;
$monthly-date-picker--day--indicator-d--color: $brand--mustard;
// for wide (7 column) version
$monthly-date-picker--days-of-week--bg-color: $brand--green;
$monthly-date-picker--days-of-week--color: $color--lightest-green;
$monthly-date-picker--day--other-month--bg-color: $color--lightest-grey;
$monthly-date-picker--day--other-month--color: rgba($brand--light-grey, 0.4);

.monthly-date-picker {
    transition: opacity 300ms ease;
    background-color: $monthly-date-picker--bg-color;
    color: $monthly-date-picker--color;
    &[data-width-watcher] {
        opacity: 0;
        &.width-watched {
            opacity: 1;
        }
    }
    &:not([data-width-watcher]).loading, &[data-width-watcher].width-watched.loading {
        position: relative;
        opacity: 0.7;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            cursor: wait;
            z-index: 10;
        }
    }
    span.indicator {
        --width-and-height: 0.75em;
        --indicator-color: #{$monthly-date-picker--day--indicator-a--color};
        display: inline-block;
        width: 0;
        height: 0;
        border-top: var(--width-and-height) solid var(--indicator-color);
        border-right: var(--width-and-height) solid transparent;
        pointer-events: none;
        &.indicator-a {
            --indicator-color: #{$monthly-date-picker--day--indicator-a--color};
        }
        &.indicator-b {
            --indicator-color: #{$monthly-date-picker--day--indicator-b--color};
        }
        &.indicator-c {
            --indicator-color: #{$monthly-date-picker--day--indicator-c--color};
        }
        &.indicator-d {
            --indicator-color: #{$monthly-date-picker--day--indicator-d--color};
        }
    }
    .monthly-date-picker--controls {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .btn {
            flex: 0 0 auto;
        }
        .dropdown-menu {
            flex: 1 1 auto;
        }
    }
    .days-of-week,
    .monthly-date-picker--month {
        @include list-unstyled;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        gap: $ecommerce-spacer;
        li {
            display: flex;
            margin: 0;
            width: 100%;
            .monthly-date-picker--day {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                flex: 1 1 auto;
                border: 0;
                padding: 10px;
                background: $monthly-date-picker--day--bg-color;
                color: $monthly-date-picker--day--color;
                font-family: $alt-headings-font-family;
                font-weight: $alt-headings-font-weight;
                line-height: $alt-headings-line-height;
                font-size: 1.2em;
                transition: background-color 300ms ease, color 300ms ease;
                span.indicator {
                    --width-and-height: 10px;
                    display: block;
                    position: absolute;
                    top: $ecommerce-spacer;
                    left: $ecommerce-spacer;
                    z-index: 2;
                    // deal with up to 3 indicators in one day cell
                    &:nth-child(4) {
                        top: calc((var(--width-and-height) * .66) + 2px);
                        z-index: 3;
                    }
                    &:nth-child(5) {
                        top: calc((var(--width-and-height) * 1.33) + 2px);
                        z-index: 4;
                    }
                }
                &.unavailable {
                    background: $monthly-date-picker--day--unavailable--bg-color;
                    color: $monthly-date-picker--day--unavailable--color;
                    cursor: not-allowed;
                    span.indicator {
                        opacity: 0.2;
                    }
                }
                &.sold-out {
                    cursor: not-allowed;
                    .sold-out-notice {
                        position: absolute;
                        top: calc(50% - 1.5px);
                        left: 20%;
                        width: 60%;
                        height: 3px;
                        overflow: hidden;
                        transform: rotate(-25deg);
                        background-color: $monthly-date-picker--day--sold-out--color;
                    }
                }
                &.today {
                    background: $monthly-date-picker--day--today--bg-color;
                    color: $monthly-date-picker--day--today--color;
                }
                &.selected {
                    background: $monthly-date-picker--day--selected--bg-color;
                    color: $monthly-date-picker--day--selected--color;
                }
            }
            button.monthly-date-picker--day {
                padding-right: 2rem;
                &:after {
                    display: block;
                    content: "";
                    position: absolute;
                    top: calc(50% - 0.5rem);
                    right: 0.4rem;
                    width: 1rem;
                    height: 1rem;
                    background-image: $monthly-date-picker--day--selected--icon-svg;
                    background-repeat: no-repeat;
                    background-size: contain;
                    z-index: 2;
                    opacity: 0;
                    transform: scale(0.2);
                    transition: all 300ms ease;
                }
                &:not(.selected) {
                    &:hover,
                    &:focus {
                        background: $monthly-date-picker--day--bg-color--hover;
                        color: $monthly-date-picker--day--color--hover;
                    }
                }
                &.today {
                    background: $monthly-date-picker--day--today--bg-color;
                    color: $monthly-date-picker--day--today--color;
                }
                &.selected {
                    background: $monthly-date-picker--day--selected--bg-color;
                    color: $monthly-date-picker--day--selected--color;
                    &:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }
    dl.monthly-date-picker--key {
        margin: 2px 0;
        dt.monthly-date-picker--key--item {
            display: block;
            margin-top: 15px;
            font-family: $alt-headings-font-family;
            font-weight: $alt-headings-font-weight;
            line-height: $alt-headings-line-height;
            margin: 0;
            .indicator {
                margin-right: 0.5em;
            }
            &#monthly-date-picker--sold-out--indicator .sold-out-indicator {
                display: inline-block;
                width: 15px;
                height: 0.8em;
                position: relative;
                margin-right: 0.5em;
                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: calc(50% - 1.5px);
                    left: 0;
                    width: 100%;
                    height: 3px;
                    overflow: hidden;
                    transform: rotate(-25deg);
                    background-color: $monthly-date-picker--day--sold-out--color;
                }
            }
        }
        dd {
            margin: 0;
        }
    }
    &:not(.wide) {
        .days-of-week {
            display: none;
        }
        .past-date {
            display: none;
        }
        .monthly-date-picker--month {
            max-height: $ecommerce-scroll-limits--fallback;
            max-height: $ecommerce-scroll-limits--clamp;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                appearance: none;
                width: 12px;
                background-color: rgba(0, 0, 0, 0.5);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                box-sizing: border-box;
                background-clip: content-box;
                border: 2px solid transparent;
                background-color: rgba(255, 255, 255, 0.5);
                cursor: pointer;
                &:hover,
                &:active {
                    background-color: rgba(255, 255, 255, 0.8);
                }
            }
            li.other-month {
                display: none;
            }
            .monthly-date-picker--day {
                .weekday {
                    margin-right: 5px;
                }
                &.sold-out {
                    .sold-out-notice {
                        top: calc(50% - 1px);
                        left: 10px;
                        width: 4em;
                        height: $ecommerce-spacer;
                        transform: rotate(-25deg);
                    }
                }
            }
        }
    }
    &.wide {
        .days-of-week,
        .monthly-date-picker--month {
            gap: $ecommerce-spacer;
            li {
                width: calc((100% - (6 * #{$ecommerce-spacer})) / 7);
                justify-content: center;
            }
            &:before, &:after {
                display: none;
            }
        }
        .days-of-week {
            background: $monthly-date-picker--days-of-week--bg-color;
            color: $monthly-date-picker--days-of-week--color;
            padding: 10px $ecommerce-spacer;
            text-transform: uppercase;
            font-size: 0.8rem;
            line-height: 0.8rem;
            abbr {
                text-decoration: none;
                cursor: default;
            }
        }
        .monthly-date-picker--month {
            margin-top: $ecommerce-spacer;
            .monthly-date-picker--day {
                justify-content: center;
                align-items: center;
                padding: 15px $ecommerce-spacer;
                border: 0;
                .weekday {
                    display: none;
                }
                .day {
                    display: block;
                    width: 100%;
                    text-align: center;
                }
            }
            li.other-month {
                display: flex;
                .monthly-date-picker--day {
                    background: $monthly-date-picker--day--other-month--bg-color;
                    color: $monthly-date-picker--day--other-month--color;
                }
            }
        }
    }
}


// ********************************
// *****  time-slot selector  *****
// ********************************

$time-slot-selector--bg-color: transparent;
$time-slot-selector--expander--bg-color: $brand--green;
$time-slot-selector--expander--color: $brand--white;
$time-slot-selector--slot--bg-color: $color--lightest-green;
$time-slot-selector--slot--color: $brand--slate;
$time-slot-selector--slot--bg-color--hover: $brand--vivid-pink;
$time-slot-selector--slot--color--hover: $brand--white;
$time-slot-selector--slot--color--status--none: $brand--slate;
$time-slot-selector--slot--color--status--normal: $time-slot-selector--slot--color;
$time-slot-selector--slot--color--status--warning: $danger;
$time-slot-selector--slot--color--status--limited: $danger;
$time-slot-selector--slot--selected--bg-color: $brand--slate;
$time-slot-selector--slot--selected--color: $brand--white;
$time-slot-selector--slot--selected--icon-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Cpath fill='%23d8d027' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z' /%3E%3C/svg%3E");


.time-slot-selector {
    .loading & {
        position: relative;
        opacity: 0.7;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            cursor: wait;
            z-index: 10;
        }
    }
    .expander {
        background-color: $time-slot-selector--bg-color;
        border: 0;
        .expander-header {
            background-color: $time-slot-selector--expander--bg-color;
            color: $time-slot-selector--expander--color;
            padding: 0.375rem 0.75rem;
            margin-bottom: $ecommerce-spacer;
            button {
                color: $time-slot-selector--expander--color;
                padding: 0;
                &[aria-expanded="false"] {
                    cursor: pointer;
                }
                &[aria-expanded="true"] {
                    pointer-events: none;
                }
                .indicator {
                    font-size: 1rem;
                    line-height: 1rem;
                }
            }
        }
        .expander-content {
            padding: 0;
        }
    }
    ol {
        display: flex;
        flex-direction: column;
        gap: $ecommerce-spacer;
        margin: 0 0 $ecommerce-spacer;
        max-height: $ecommerce-scroll-limits--fallback;
        max-height: $ecommerce-scroll-limits--clamp;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            appearance: none;
            width: 12px;
            background-color: rgba(0, 0, 0, 0.5);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            box-sizing: border-box;
            background-clip: content-box;
            border: 2px solid transparent;
            background-color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
            &:hover,
            &:active {
                background-color: rgba(255, 255, 255, 0.8);
            }
        }
        li {
            margin: 0;
            button {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;
                background-color: $time-slot-selector--slot--bg-color;
                color: $time-slot-selector--slot--color;
                border: 0;
                padding: 10px;
                margin: 0;
                .time {
                    display: block;
                    flex: 0 0 auto;
                    min-width: 8rem;
                    text-align: left;
                    font-family: $alt-headings-font-family;
                    font-weight: $alt-headings-font-weight;
                    line-height: $alt-headings-line-height;
                    font-size: 1.2em;
                    &:after {
                        display: inline-block;
                        content: "";
                        width: 1rem;
                        height: 1rem;
                        margin-left: 0.5rem;
                        background-image: $time-slot-selector--slot--selected--icon-svg;
                        background-repeat: no-repeat;
                        background-size: contain;
                        z-index: 2;
                        opacity: 0;
                        transform: scale(0.2);
                        transition: all 300ms ease;
                    }
                }
                .details {
                    display: inline-flex;
                    flex: 1 1 auto;
                    justify-content: space-between;
                    .availability {
                        display: block;
                        color: $time-slot-selector--slot--color--status--normal;
                    }
                    .price {
                        display: block;
                        text-align: right;
                        font-family: $alt-headings-font-family;
                        font-weight: $alt-headings-font-weight;
                        line-height: $alt-headings-line-height;
                        font-size: 1.2em;
                    }
                }
                &.selected {
                    background-color: $time-slot-selector--slot--selected--bg-color;
                    color: $time-slot-selector--slot--selected--color;
                    .time:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                    .availability {
                        color: $time-slot-selector--slot--selected--color;
                    }
                }
                &:not(.selected, .disabled, :disabled) {
                    &:hover,
                    &:focus {
                        background-color: $time-slot-selector--slot--bg-color--hover;
                        color: $time-slot-selector--slot--color--hover;
                        .availability {
                            color: $time-slot-selector--slot--color--hover !important;
                        }
                    }
                }
                &[data-status="limited"]:not(.selected) {
                    .availability {
                        color: $time-slot-selector--slot--color--status--limited;
                    }
                }
                &[data-status="warning"]:not(.selected) {
                    .availability {
                        color: $time-slot-selector--slot--color--status--warning;
                    }
                }
                &[data-status="none"] {
                    cursor: not-allowed;
                    .availability {
                        color: $time-slot-selector--slot--color--status--none;
                    }
                    .price {
                        display: none;
                    }
                }
            }
        }
        li:first-child button {
            border-top: 0;
        }
    }
}

.membership-details-group {

    &--item {
        .membership-details-group--item--header--summary {
            transition: opacity 300ms linear;
            will-change: opacity;
        }
        &[aria-expanded="true"] {
            .membership-details-group--item--header--summary {
                opacity: 0;
                pointer-events: none;
            }
        }
        // inactive pre-edited items
        &[aria-expanded="false"]:not(.editable) {
            .membership-details-group--item--header {
                opacity: 0.5;
                pointer-events: none;
                cursor: not-allowed;
                &--summary {
                    display: none !important;
                }
            }
        }
    }

}


// *******************************
// *****  Checkout elements  *****
// *******************************

// checkout - time remaining

#checkout-time-remaining {
    margin: 0;
    padding: 0.75rem .5rem;
    background-color: $brand--green;
    color: white;
    font-size: 1.125rem;
    font-family: $alt-headings-font-family;
    font-weight: $alt-headings-font-weight;
    line-height: $alt-headings-line-height;
    text-align: center;
    letter-spacing: 0.01em;
    abbr {
        text-decoration: none;
        cursor: default;
    }
    @include media-breakpoint-up(md) {
        padding: 1.25rem .5rem .75rem;
    }
    @include media-breakpoint-up(lg) {
        padding: 1.5rem .5rem .5rem;
    }
}

// checkout - extras listing

.checkout--extras-listing {
    display: flex;
    flex-direction: column;
    gap: $ecommerce-spacer;
    &--item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        &--top-row {
            display: flex;
            width: 100%;
            flex-direction: row;
            gap: 10px;
            @include media-breakpoint-up(md) {
                gap: 15px;
            }
        }
        &--bottom-row {
            display: flex;
            width: 100%;
            flex-direction: row;
            gap: 10px;
            justify-content: flex-end;
            align-items: center;
        }
        &--image {
            width: 80px;
            flex: 0 0 auto;
            aspect-ratio: 1;
            @include media-breakpoint-up(md) {
                width: 100px;
            }
        }
        &--details {
            flex: 1 1 auto;
        }
        &--price {
            flex: 0 0 auto;
            p {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                white-space: nowrap;
                margin-left: auto;
            }
        }
        &--controls {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 30px;
            p {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                white-space: nowrap;
            }
            .basket-status-update {
                font-size: 0.875rem;
                font-weight: $font-weight-bold;
            }
        }
        @include media-breakpoint-down(sm) {
            .checkout--extras-listing--item--top-row {
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 5px;
            }
            .checkout--extras-listing--item--details {
                width: 100%;
                flex: 0 0 auto;
            }
            .checkout--extras-listing--item--controls {
                width: 100%;
                flex-direction: space-between;
            }
            &.checkout--extras-listing--item--with-image {
                .checkout--extras-listing--item--image {
                    order: 2;
                }
                .checkout--extras-listing--item--details {
                    order: 1;
                }
                .checkout--extras-listing--item--price {
                    order: 3;
                }
            }
            &:not(.checkout--extras-listing--item--with-image) {
                .checkout--extras-listing--item--price {
                    width: 100%;
                    flex-direction: row;
                    align-items: flex-start;
                    margin-left: 0;
                }
            }
        }
    }
}

.modal.checkout--extras--modal {
    .modal-dialog {
        max-width: 700px;
        &.modal-dialog-scrollable .modal-content {
            overflow-y: auto;
        }
        .checkout--extras--modal--header {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            h1, .btn.close {
                flex: 1 1 auto;
            }
        }
    }
}

// sub form

.sub-form--bg-color {
    background: var(--theme--sub-form--bg-color);
}

.manual-address-entry {
    margin: 0 -#{map-get($spacers,3)};
    .manual-address-entry--inner {
        background: var(--theme--sub-form--bg-color);
        margin-top: map-get($spacers,4);
        padding: map-get($spacers,4) map-get($spacers,3);
    }
}

.sub-form {
    margin: 0 -#{map-get($spacers,3)};
    .sub-form--inner {
        background: var(--theme--sub-form--bg-color);
        margin-top: map-get($spacers,4);
        padding: map-get($spacers,4) map-get($spacers,3);
    }
}

// delivery listing

.delivery-listing {
    display: flex;
    flex-direction: column;
    border-top: 1px var(--theme--rule--color) solid;
    & > li {
        padding: 20px 0;
        border-bottom: 1px var(--theme--rule--color) solid;
    }
    &--item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        @include media-breakpoint-up(md) {
            gap: 15px;
        }
        &--image {
            width: 60px;
            flex: 0 0 auto;
            aspect-ratio: 1;
            @include media-breakpoint-up(md) {
                width: 80px;
            }
        }
        &--details {
            flex: 1 1 auto;
        }
    }
}

// booking confirmation

.booking-confirmation-summary-table {
    width: 100%;
    td,th {
        padding: 8px 0 8px 10px;
        &:first-child {
            padding-left: 0;
        }
    }
    tbody tr:last-child {
        td,th {
            padding-bottom: 18px;
        }
    }
    tfoot {
        border-top: 1px solid var(--theme--rule--color);
        td,th {
            padding-top: 18px;
            padding-bottom: 0;
        }
    }
}
