// theme mixins

@mixin on-light-theme-vars {
    // basic
    --theme--color: #{$brand--slate};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: #{$brand--green};
    --theme--color--emphasis--invert: #{$brand--white};
    // for minor text, helper text etc
    --theme--color--subtle: #{$text-muted};
    // links
    --theme--link--color: #{$link-color};
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: #{$link-hover-color};
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: #{$primary};
    --theme--rule--color: #{$color--lighter-grey};
    // button - standard
    --theme--btn--bg-color: #{$brand--green};
    --theme--btn--border-color: #{$brand--green};
    --theme--btn--color: #{$brand--white};
    --theme--btn--bg-color--hover: #{$brand--vivid-pink};
    --theme--btn--border-color--hover: #{$brand--vivid-pink};
    --theme--btn--color--hover: #{$brand--white};
    // button - primary
    --theme--btn--primary--bg-color: #{$brand--green};
    --theme--btn--primary--border-color: #{$brand--green};
    --theme--btn--primary--color: #{$brand--white};
    --theme--btn--primary--bg-color--hover: #{$brand--vivid-pink};
    --theme--btn--primary--border-color--hover: #{$brand--vivid-pink};
    --theme--btn--primary--color--hover: #{$brand--white};
    // button - secondary
    --theme--btn--secondary--bg-color: #{$color--grey};
    --theme--btn--secondary--border-color: #{$color--grey};
    --theme--btn--secondary--color: #{$brand--white};
    --theme--btn--secondary--bg-color--hover: #{$brand--vivid-pink};
    --theme--btn--secondary--border-color--hover: #{$brand--vivid-pink};
    --theme--btn--secondary--color--hover: #{$brand--white};
    // sub-form
    --theme--sub-form--bg-color: #{$color--lighter-grey};
}

@mixin on-dark-theme-vars {
    // basic
    --theme--color: #{$light};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: #{$brand--white};
    --theme--color--emphasis--invert: #{$dark};
    // for minor text, helper text etc
    --theme--color--subtle: #{lighten($brand--stone, 10%)};
    // links
    --theme--link--color: #{$brand--white};
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: #{$brand--white};
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--white};
    --theme--rule--color: #{$brand--light-grey};
    // button - standard
    --theme--btn--bg-color: #{$brand--cream};
    --theme--btn--border-color: #{$brand--cream};
    --theme--btn--color: #{$brand--slate};
    --theme--btn--bg-color--hover: #{$brand--vivid-pink};
    --theme--btn--border-color--hover: #{$brand--vivid-pink};
    --theme--btn--color--hover: #{$brand--white};
    // button - primary
    --theme--btn--primary--bg-color: #{$brand--white};
    --theme--btn--primary--border-color: #{$brand--white};
    --theme--btn--primary--color: #{$brand--slate};
    --theme--btn--primary--bg-color--hover: #{$brand--vivid-pink};
    --theme--btn--primary--border-color--hover: #{$brand--vivid-pink};
    --theme--btn--primary--color--hover: #{$brand--white};
    // button - secondary
    --theme--btn--secondary--bg-color: #{$color--lighter-green};
    --theme--btn--secondary--border-color: #{$color--lighter-green};
    --theme--btn--secondary--color: #{$brand--white};
    --theme--btn--secondary--bg-color--hover: #{$brand--vivid-pink};
    --theme--btn--secondary--border-color--hover: #{$brand--vivid-pink};
    --theme--btn--secondary--color--hover: #{$brand--white};
    // sub-form
    --theme--sub-form--bg-color: #{$color--grey};
}

// other mixins

@mixin non-retina-font-smoothing-antialiased {
    @media(-webkit-min-device-pixel-ratio: 1) {
        -webkit-font-smoothing: antialiased;
    }
}
