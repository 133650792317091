.btn-unstyled {
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    background-color: transparent;
}

a, .btn, button {
    svg.fa-arrow-right, svg.fa-angle-right, svg.fa-arrow-left, svg.fa-angle-left, svg.fa-arrow-down, svg.fa-angle-down  {
        will-change: transform;
        transition: transform 200ms ease-in-out;
    }
    svg.fa-arrow-right, svg.fa-angle-right {
        margin-left: 0.3em;
    }
    svg.fa-arrow-left, svg.fa-angle-left {
        margin-right: 0.3em;
    }
    svg.fa-arrow-down, svg.fa-angle-down  {
        margin-left: 0.3em;
    }
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            svg.fa-arrow-right, svg.fa-angle-right {
                transform: translate3d(0.3em,0,0);
            }
            svg.fa-arrow-left, svg.fa-angle-left {
                transform: translate3d(-0.3em,0,0);
            }
        }
        &[aria-expanded="true"] {
            svg.fa-arrow-down, svg.fa-angle-down  {
                transform: scale(1) rotate(180deg);
            }
        }
    }
    &:disabled, &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.btn-link {
    border-radius: 0;
}
.btn {
    font-family: $alt-headings-font-family;
    font-weight: $alt-headings-font-weight;
    line-height: $alt-headings-line-height;
    letter-spacing: 0.01em;
    &.btn-primary {
        background-color: var(--theme--btn--primary--bg-color);
        border-color: var(--theme--btn--primary--bg-color);
        color: var(--theme--btn--primary--color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--primary--bg-color--hover);
                border-color: var(--theme--btn--primary--bg-color--hover);
                color: var(--theme--btn--primary--color--hover);
            }
        }
    }
    &.btn-secondary {
        background-color: var(--theme--btn--secondary--bg-color);
        border-color: var(--theme--btn--secondary--bg-color);
        color: var(--theme--btn--secondary--color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--secondary--bg-color--hover);
                border-color: var(--theme--btn--secondary--bg-color--hover);
                color: var(--theme--btn--secondary--color--hover);
            }
        }
    }
    &.btn-outline-primary {
        border-color: var(--theme--btn--primary--bg-color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--primary--bg-color--hover);
                border-color: var(--theme--btn--primary--bg-color--hover);
                color: var(--theme--btn--primary--color--hover);
            }
        }
    }
    &.btn-outline-secondary {
        border-color: var(--theme--btn--secondary--bg-color);
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--theme--btn--secondary--bg-color--hover);
                border-color: var(--theme--btn--secondary--bg-color--hover);
                color: var(--theme--btn--secondary--color--hover);
            }
        }
    }
}

.btn-theme {
    --bg-color: var(--theme--btn--primary--bg-color);
    --bg-color--hover: var(--theme--btn--primary--bg-color--hover);
    --color--hover: var(--theme--btn--primary--color--hover);
    position: relative;
    background-color: var(--bg-color);
    will-change: transform;
    transition: background-color 200ms linear, color 200ms linear;
    border-width: 0;
    padding: calc(#{$input-btn-padding-y} + (2 * #{$btn-border-width}))  calc(#{$btn-padding-x} + (2 * #{$btn-border-width}));
    &.btn-lg {
        padding: calc(#{$input-btn-padding-y-lg} + (2 * #{$btn-border-width}))  calc(#{$btn-padding-x-lg} + (2 * #{$btn-border-width}));
    }
    &.btn-sm {
        padding: calc(#{$input-btn-padding-y-sm} + (2 * #{$btn-border-width}))  calc(#{$btn-padding-x-sm} + (2 * #{$btn-border-width}));
    }
    // hover/focus
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            background-color: var(--bg-color--hover);
            color: var(--color--hover);
        }
    }
    // variations
    &.btn-secondary {
        --bg-color: #{$brand--dusty-pink};
    }
    &.btn-success {
        --bg-color: #{$success};
    }
    &.btn-info {
        --bg-color: #{$info};
    }
    &.btn-warning {
        --bg-color: #{$warning};
    }
    &.btn-danger {
        --bg-color: #{$danger};
    }
    // outline variations
    &.btn-outline-primary, &.btn-outline-secondary, &.btn-outline-success, &.btn-outline-info, &.btn-outline-warning, &.btn-outline-danger, &.btn-outline-lg, &.btn-outline-sm {
        background-color: transparent;
        background-image: linear-gradient(90deg,var(--bg-color--hover) 50%, transparent 0);
        border-width: $btn-border-width;
        padding: $input-btn-padding-y $btn-padding-x;
        &.btn-lg {
            padding: $input-btn-padding-y-lg $btn-padding-x-lg;
        }
        &.btn-sm {
            padding: $input-btn-padding-y-sm $btn-padding-x-sm;
        }
        &:not(:disabled):not(.disabled) {
            &:hover, &:focus {
                background-color: var(--bg-color--hover);
                border-color: var(--bg-color--hover);
            }
        }
    }
}
