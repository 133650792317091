﻿.expander {
    border-top: $expander--border-top;
    border-right: $expander--border-right;
    border-bottom: $expander--border-bottom;
    border-left: $expander--border-left;
    padding: 0 0 calc(#{$expander--padding-bottom} - #{$expander--gap}) 0;
    .expander--header {
        padding: $expander--padding-top $expander--padding-right $expander--gap $expander--padding-left;
        h1, h2, h3, h4, h5, h6 {
            margin: 0;
            padding: 0;
        }
        button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            border: 0;
            padding: 0;
            background-color: transparent;
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            text-transform: inherit;
            line-height: inherit;
            color: var(--theme--link--color);
            text-align: left;
            &:hover, &:focus {
                color: var(--theme--link--color--hover);
                text-decoration: var(--theme--link--text-decoration--hover);
            }
        }
    }
    .expander--content {
        padding: 0 $expander--padding-right $expander--gap $expander--padding-left;
        & > *:last-child {
            margin-bottom: 0;
        }
    }

    // when in an accordion

    .accordion & {
        &:not(:last-child) {
            border-bottom: 0;
        }
    }

    // nested inside a panel

    .panel .panel--inner & {
        border: 0;
        background-color: transparent;
        .expander--header {
            padding: 0 0 $expander--gap 0;
        }
        .expander--content {
            padding: 0 0 $expander--gap 0;
        }
        &:last-child {
            margin-bottom: -$expander--gap;
        }
    }
    .panel .panel--inner > &:not(:first-child) {
        border-top: $expander--panel-divider--border-top;
        padding-top: $expander--panel-divider--padding-top;
    }
    .panel .panel--inner > .accordion > &:not(:first-child) {
        border-top: $expander--panel-divider--border-top;
        padding-top: $expander--panel-divider--padding-top;
    }
}
