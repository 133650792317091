// light themes

.theme--white, .theme--cream, .theme--lightest-cream, .theme--lightest-green, .theme--lightest-grey {
    @include on-light-theme-vars;
}
.theme--white {
    --theme--background-color: #{$brand--white};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
}
.theme--cream {
    --theme--background-color: #{$brand--cream};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{darken($brand--cream,15%)};
    --theme--sub-form--bg-color: #{darken($brand--cream,5%)};
}
.theme--lightest-cream {
    --theme--background-color: #{lighten($brand--cream, 3%)};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{darken($brand--cream,10%)};
    --theme--sub-form--bg-color: #{darken($brand--cream,5%)};
}
.theme--lightest-green {
    --theme--background-color: #{$color--lightest-green};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{$brand--white};
    --theme--sub-form--bg-color: #{darken($color--lightest-green,5%)};
}
.theme--lightest-grey {
    --theme--background-color: #{$color--lightest-grey};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{$brand--white};
    --theme--sub-form--bg-color: #{darken($color--lighter-grey,5%)};
}

// dark themes

.bg--transparent {
    --theme--background-color: transparent;
    background: transparent !important;
}

.theme--green, .theme--lighter-green, .theme--brown, .theme--black, .theme--slate, .theme--vivid-pink {
    @include on-dark-theme-vars;
}
.theme--green {
    --theme--background-color: #{$brand--green};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{lighten($brand--green, 15%)};
    --theme--sub-form--bg-color: #{lighten($brand--green, 5%)};
}
.theme--lighter-green {
    --theme--background-color: #{$color--lighter-green};
    background: var(--theme--background-color);
    --theme--color: #{$brand--white};
    --theme--color--emphasis--invert: var(--theme--background-color);
    --theme--color--subtle: #{$brand--cream};
    --theme--rule--color: #{$brand--white};
    --theme--btn--secondary--bg-color: #{$brand--green};
    --theme--btn--secondary--border-color: #{$brand--green};
    --theme--sub-form--bg-color: #{lighten($brand--green, 5%)};
}
.theme--brown {
    --theme--background-color: #{$brand--brown};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{lighten($brand--brown, 15%)};
    --theme--sub-form--bg-color: #{lighten($brand--brown, 5%)};
}
.theme--vivid-pink {
    --theme--background-color: #{$brand--vivid-pink};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{lighten($brand--vivid-pink, 15%)};
    --theme--sub-form--bg-color: #{lighten($brand--vivid-pink, 5%)};
}
.theme--slate {
    --theme--background-color: #{$brand--slate};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{lighten($brand--slate, 15%)};
    --theme--sub-form--bg-color: #{lighten($brand--slate, 5%)};
}
.theme--black {
    --theme--background-color: black;
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{$brand--slate};
    --theme--sub-form--bg-color: #{lighten(black, 5%)};
}
