﻿// ********************
// **  Site colours  **
// ********************

$brand--white:                  white;
$brand--brown:                  #5f3926;
$brand--cream:                  #fdf5e6; // slightly darker than the official #fff7e9;
$brand--aubergine:              #78283c;
$brand--lime:                   #d8d027;
$brand--orange:                 #eb7007;
$brand--light-brown:            #967448;
$brand--dusty-pink:             #cc7a81;
$brand--vivid-pink:             #d31267;
$brand--mustard:                #f4bf0c;
$brand--slate:                  #4c5a62;
$brand--light-green:            #99b66c;
$brand--green:                  #496d5a;
$brand--sand:                   #ffe186;
$brand--light-grey:             #b6babd;
$brand--stone:                  #d1c9a0;
$brand--tab:                    #cba574;
$brand--baby-blue:              #bacceb;
$brand--moss:                   #9daa80;
$brand--red:                    #b21917;


$color--lighter-green:          #7aa48d;
$color--lightest-green:         #edf2e4;
$color--grey:                   #929ba1;
$color--lighter-grey:           #d9dce0;
$color--lightest-grey:          #f0f1f2;


// ******************************************************************
// **                                                              **
// **   BOOTSTRAP VARIABLES - (overriding bootstrap defaults)      **
// **                                                              **
// ******************************************************************


// set fundamental bootstrap colour variables

$primary:                       $brand--green;
$secondary:                     $brand--vivid-pink;
$light:                         $color--lightest-green;
$dark:                          $brand--slate;

$text-muted:                    darken($brand--moss, 10%);

$success:                       #709d2e;
$info:                          $brand--baby-blue;
$warning:                       $brand--mustard;
$danger:                        $brand--red;

$body-bg:                       $brand--white;
$body-color:                    $dark;

$link-color:                    $primary;
$link-decoration:               none;
$link-hover-color:              $brand--vivid-pink;
$link-hover-decoration:         underline;


// Fonts

$font-family-sans-serif:      'Open Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-family-base:            $font-family-sans-serif;
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-weight-base:            400;
$line-height-base:            1.4;

$font-weight-bold:            600;

$headings-font-family:        'Plantin MT W01 Light', 'Times New Roman', Times, serif;
$headings-font-weight:        400;
$headings-line-height:        1.1;
$headings-font-style:         normal;

$alt-headings-font-family:    'Barlow Condensed', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$alt-headings-font-weight:    500;
$alt-headings-line-height:    1.1;
$alt-headings-font-style:     normal;

$h1-font-size:                $font-size-base * 2.4;
$h2-font-size:                $font-size-base * 1.8;
$h3-font-size:                $font-size-base * 1.6;
$h4-font-size:                $font-size-base * 1.4;
$h5-font-size:                $font-size-base * 1.2;
$h6-font-size:                $font-size-base;
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
);

$display-font-sizes: (
  1: 3.6rem,
  2: 3.4rem,
  3: 3.2rem,
  4: 3rem,
  5: 2.8rem,
  6: 2.6rem
);

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        // added
        6: (
            $spacer * 4,
        ),
        // added
        7:
            (
                $spacer * 5,
            ),
        // added
        8:
            (
                $spacer * 6,
            )
            // added,
    ),
    $spacers
);

// for ecommerce element gaps etc
$ecommerce-spacer:            2px;

// Buttons

$border-radius:                 6px;
$border-radius-sm:              $border-radius;
$border-radius-lg:              $border-radius;

$input-btn-padding-y:           .65rem;
$btn-padding-x:                 1.25rem;

$input-btn-padding-y-sm:        .5rem;
$btn-padding-x-sm:              1rem;
$input-btn-font-size-sm:        1rem;

$input-btn-padding-y-lg:        0.85rem;
$btn-padding-x-lg:              1.5rem;
$input-btn-font-size-lg:        1.4rem;

$input-border-radius:           0;
$input-border-radius-sm:        $input-border-radius;
$input-border-radius-lg:        $input-border-radius;

$btn-font-family:               $alt-headings-font-family;
$btn-font-size:                 1.2rem;

$dropdown-border-radius:        0;

// Alerts

$alert-border-radius:         0;

// Cards

$card-border-width:                 0;
$card-border-radius:                0;
$card-cap-bg:                       transparent;
$card-bg:                           $brand--cream;

// Modals

$modal-content-border-width:        0;
$modal-content-border-radius:       0;



// ************************
// **                    **
// **   SITE VARIABLES   **
// **                    **
// ************************

// **************
// **  Panels  **
// **************

$panel--padding: (
    xs: 15px,
    sm: 20px,
    md: 20px,
    lg: 20px,
    xl: 24px,
    xxl: 24px
);

// *****************
// **  Ecommerce  **
// *****************

$ecommerce-scroll-limits--fallback:    50vh;
$ecommerce-scroll-limits--clamp:       clamp(200px, 50vh, 400px);

// *****************
// **  Promo bar  **
// *****************

$promo-bar--height: (
    xs: 45px,
    sm: 45px,
    md: 50px,
    lg: 50px,
    xl: 65px,
    xxl: 70px
);

// *****************
// **  Hero area  **
// *****************

$hero--fullscreen--height-reduction--mobile:             30px;
$hero--fullscreen--height-reduction--desktop:            30px;
$hero--img--min-heights: (
    xs: calc(var(--vpWidth) * .75),
    sm: calc(var(--vpWidth) * .75),
    md: 300px,
    lg: 350px,
    xl: 400px,
    xxl: 500px
);
$hero--img--heights: (
    xs: calc(var(--vpWidth) * .75),
    sm: calc(var(--vpWidth) * .75),
    md: var(--vpHeight),
    lg: var(--vpHeight),
    xl: var(--vpHeight),
    xxl: var(--vpHeight)
);
$hero--img--max-heights: (
    xs: calc(var(--vpWidth) * .75),
    sm: calc(var(--vpWidth) * .75),
    md: 450px,
    lg: 500px,
    xl: 550px,
    xxl: 600px
);

// ***************************
// **  50/50 split layouts  **
// ***************************

$split-img-content--content--margin--lg:    30px;
$split-img-content--content--margin--xl:    30px;
$split-img-content--content--margin--xxl:   30px;

// ***********************
// **  Image galleries  **
// ***********************

$image-gallery--small-thumb-height: 120px;
$image-gallery--medium-thumb-height: 180px;
$image-gallery--large-thumb-height: 240px;
$image-gallery--thumb--margin: 5px;
$image-gallery--thumb--bg-color: $brand--slate;

// *****************
// **  Expanders  **
// *****************

$expander--border-top:                      1px solid var(--theme--rule--color);
$expander--border-right:                    1px solid var(--theme--rule--color);
$expander--border-bottom:                   1px solid var(--theme--rule--color);
$expander--border-left:                     1px solid var(--theme--rule--color);
$expander--padding-top:                     15px;
$expander--padding-right:                   15px;
$expander--padding-bottom:                  15px;
$expander--padding-left:                    15px;
$expander--gap:                             1rem;
$expander--panel-divider--border-top:       1px solid var(--theme--rule--color);
$expander--panel-divider--padding-top:      15px;

// ******************
// **  Countdowns  **
// ******************

// All sizes are in em units as they are relative to the font size
$countdown--font-size--default: 2rem;
$countdown--font-size--large: 3rem;
$countdown--font-size--small: 1.2rem;
// digits
$countdown--digit--font-family: $alt-headings-font-family;
$countdown--digit--font-weight: $alt-headings-font-weight;
$countdown--digit--font-style: $alt-headings-font-style;
// column labels
$countdown--column-label--height: 0.6em; // use 'em' units 
$countdown--column-label--font-size: 0.4em; // use 'em' units
$countdown--column-label--font-family: $alt-headings-font-family;
$countdown--column-label--font-weight: $alt-headings-font-weight;
$countdown--column-label--font-style: $alt-headings-font-style;
$countdown--column-label--text-transform: uppercase;
$countdown--column-label--text-align: right;
// color settings (using themes as default)
$countdown--digit--bgcolor: black;
$countdown--digit--color: white;
$countdown--digit--border-color: var(--theme--color--subtle);
$countdown--column-label--color: var(--theme--color--subtle);
