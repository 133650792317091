h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--theme--color--emphasis);
    margin-bottom: max(1rem, 0.5em);
    strong, b {
        font-weight: $headings-font-weight;
    }
    &.heading-decor {
        position: relative;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1.2em;
        padding-right: 1.2em;
        text-align: center;
        &:before, &:after {
            display: block;
            content: '';
            position: absolute;
            top: 0.2em;
            left: 0;
            width: 0.75em;
            height: 0.75em;
            background: transparent url('../../img/heading-decor.svg') left top no-repeat;
            background-size: contain;
            user-select: none;
        }
        &:after {
            left: auto;
            right: 0;
            transform: scaleX(-1);
        }
    }
}

.alt-heading-font {
    font-family: $alt-headings-font-family;
    font-weight: $alt-headings-font-weight;
    line-height: $alt-headings-line-height;
    strong, b {
        font-weight: $alt-headings-font-weight;
    }
}

.color-body {
    color: var(--theme--color) !important;
}

.text-muted {
    color: var(--theme--color--subtle) !important;
}

.no-wrap {
    white-space: nowrap;
}

b, strong, .strong {
    font-weight: $font-weight-bold;
}

.opening-status-label {
    display: inline-block;
    min-width: 5.4em;
    text-align: left;
}

// placeholder font-awesome size
i.fa-solid, i.fa-light, i.fa-regular, i.fa-sharp {
    display: inline-block;
    min-width: 1em;
    height: 1em;
}

// icon styling shims
.tripadvisor-icon, .tiktok-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}

.instagram-logo {
    --height: 1.1em;
    display: inline-block;
    width: calc(var(--height) * 3.525);
    height: var(--height);
    background: url('../../img/logos/instagram-logo.svg') no-repeat;
    background-size: 100%;
    vertical-align: middle;
    transform: translateY(2%);
}
