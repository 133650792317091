#account-menu {
    --spacing: 15px;
    .account-menu--section {
        display: block;
        margin: 0 0 30px;
        &.account-menu--home {
            display: none;
        }
        ol {
            margin: 0 calc(0 - var(--spacing)) 0 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            li {
                display: block;
                margin: 0 0 var(--spacing);
                padding: 0;
                width: 100%;
                a {
                    display: flex;
                    padding: 10px;
                    text-decoration: none;
                    background-color: $color--lightest-grey;
                    transition: background-color 100ms linear, color 100ms linear;
                    .account-menu--option--icon {
                        margin-right: 10px;
                        font-size: 36px;
                        color: $brand--green;
                        min-width: 1.25em;
                    }
                    .account-menu--option--text {
                        display: block;
                        .account-menu--option--text-title {
                            display: block;
                            margin-bottom: 5px;
                            color: $brand--slate;
                            text-decoration: none;
                            font-size: $h4-font-size;
                            font-family: $alt-headings-font-family;
                            font-weight: $alt-headings-font-weight;
                            line-height: 1.2;
                        }
                        .account-menu--option--text-description {
                            display: block;
                            font-size: 0.88889rem;
                            line-height: 1rem;
                            color: $brand--green;
                            text-decoration: none;
                        }
                    }
                    &:hover, &:focus {
                        text-decoration: none;
                        background-color: $brand--vivid-pink;
                        .account-menu--option--icon {
                            color: $color--lighter-grey;
                        }
                        .account-menu--option--text {
                            .account-menu--option--text-title {
                                color: $brand--white;
                                text-decoration: underline;
                            }
                            .account-menu--option--text-description {
                                color: $color--lighter-grey;
                                text-decoration: none;
                            }
                        }
                    }
                }
                @include media-breakpoint-up(sm) {
                    margin: 0 var(--spacing) var(--spacing) 0;
                }
                @include media-breakpoint-only(sm) {
                    width: calc((100% - var(--spacing)) / 2);
                    &:nth-child(even) {
                        margin-right: 0;
                    }
                }
                @include media-breakpoint-only(md) {
                    width: calc((100% - var(--spacing)) / 2);
                    &:nth-child(even) {
                        margin-right: 0;
                    }
                }
                @include media-breakpoint-up(md) {
                    a {
                        padding: 15px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    width: calc((100% - (var(--spacing) * 2)) / 3);
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .user-account--side-menu & {
        --spacing: 5px;
        position: sticky;
        top: 3rem;
        .account-menu--section {
            display: block;
            &.account-menu--home {
                display: block;
            }
            > h2.h5 {
                font-size: 1rem;
            }
            &:last-child {
                margin: 0;
            }
            ol {
                display: block;
                li {
                    display: block;
                    width: 100%;
                    margin: 0 0 5px;
                    &:last-child {
                        margin: 0;
                    }
                    a {
                        padding: 8px 10px;
                        align-items: center;
                        .account-menu--option--icon {
                            margin-right: 10px;
                            font-size: 20px;
                        }
                        .account-menu--option--text {
                            .account-menu--option--text-title {
                                font-size: 1.2rem;
                                line-height: 1.2;
                                margin: 0;
                            }
                            .account-menu--option--text-description {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

// address book

.account--address--panel {
    background-color: $color--lightest-green;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &.add-new-address {
        justify-content: center;
        align-items: center;
    }
    &--label {
        margin-bottom: 0;
        font-size: $h6-font-size;
        font-family: $alt-headings-font-family;
        font-weight: $alt-headings-font-weight;
        text-transform: uppercase;
        color: $brand--vivid-pink;
    }
    &--summary p {
        margin-bottom: 0;
    }
    &--tools {
        margin-top: auto;
        a {
            font-size: 0.88889rem;
            line-height: 1;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

// orders listing

.account--orders-listing--panel {
    &[aria-hidden="true"] {
        display: none;
    }
    &--date-etc {
        font-size: 0.88889rem;
        line-height: 1;
        p {
            margin-bottom: 5px;
        }
    }
    &--summary {
        margin-top: 15px;
    }
    &--details-link {
        margin-top: 15px;
        margin-bottom: 0;
    }
    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        &--date-etc {
            width: 100%;
            flex: 0 0 auto;
            p {
                display: inline-block;
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
        &--summary {
            margin-bottom: 0;
            flex: 1 1 auto;
        }
        &--details-link {
            text-align: left;
        }
    }
}

// order summary table
.account--order-summary-table {
    width: 100%;
    background-color: lighten($color--lightest-green, 10%);
    th, td {
        padding: 0.5rem;
        border-bottom: 1px solid $color--lightest-grey;
    }
    thead th {
        text-transform: uppercase;
        font-family: $alt-headings-font-family;
        font-weight: $alt-headings-font-weight;
        font-size: 1rem;
        color: $brand--green;
    }
}
