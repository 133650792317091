.list-carousel {
    li {
        margin: 0 20px !important;
        @include media-breakpoint-up(xl) {
            margin: 0 30px !important;
        }
    }
    &.carousel-active {
        position: relative;
        &:before {
            display: inline;
            content: ' ';
        }
        li {
            position: absolute;
            display: block;
            width: 100%;
            opacity: 0;
            margin: 0 !important;
            pointer-events: none;
            will-change: opacity;
            &.fade {
                transition: opacity 500ms linear;
            }
            &.item-active {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}
