@keyframes header-logo-decor-reveal {
    0% {
        opacity: 0;
        transform: scale(0.8) rotate(2deg);
        animation-timing-function: ease-in;
    }
    10% {
        opacity: 1;
        transform: scale(0.84) rotate(1.6deg);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: scale(1) rotate(0deg);;
    }
}

#header-wrapper:not(.checkout) {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 10;
    height: 0;
    pointer-events: none;

    header {
        position: relative;
        height: calc(var(--top-utility-bar--height) + var(--header--height));
        transition: transform 300ms ease-in-out;
        will-change: transform;
        background: $brand--white;
        box-shadow: 0 1px 6px rgba(black,0.25);

        .header-hidden:not(.menu-active) & {
            transform: translate3d(0,calc(-1 * (var(--top-utility-bar--height) + var(--header--height) + var(--logo-breakout-bottom))),0);
        }

        #skip-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: var(--top-utility-bar--height);
            left: 0;
            z-index: 2;
            width: 100%;
            height: var(--header--height);
            background-color: $brand--white;
            font-size: 1rem;
            line-height: 1rem;
            text-decoration: underline;
            opacity: 0;
            pointer-events: none;
            transition: opacity 200ms linear;
            &:focus {
                opacity: 1;
                pointer-events: all;
            }
        }

        .header--utility-bar {
            // links
            background-color: $brand--slate;
            color: #{$color--lightest-grey};
            --theme--color: #{$color--lightest-grey};
            --theme--link--color: #{$color--lightest-grey};
            --theme--link--color--hover: white;
            width: 100%;
            height: var(--top-utility-bar--height);
            pointer-events: all;

            .header--utility-bar--inner {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                gap: 30px;
                align-items: center;
                height: var(--top-utility-bar--height);
                font-size: 12px;
                line-height: 1;
                font-weight: 700;
                text-transform: uppercase;
                .svg-inline--fa {
                    font-size: 16px;
                    color: white;
                }

                .header--utility-bar--opening-status {
                    margin-right: auto;
                    margin-left: 0;
                    .dropdown-toggle {
                        font-family: $font-family-base;
                        font-size: 12px;
                        line-height: 1;
                        font-weight: 700;
                        text-transform: uppercase;
                        svg {
                            vertical-align: middle;
                        }
                    }
                    .dropdown-menu {
                        padding: .75rem;
                        font-family: $font-family-base;
                        font-weight: $font-weight-base;
                        line-height: $line-height-base;
                        text-transform: none;
                        font-size: 0.875rem;
                        min-width: 260px;
                        max-height: calc(var(--vpHeight) - 60px);
                        overflow-y: auto;
                        h2 {
                            text-transform: uppercase;
                        }
                        h3 {
                            color: var(--theme--color--subtle);
                        }
                        p {
                            margin: 0;
                            a {
                                color: var(--theme--link--color);
                                .svg-inline--fa {
                                    color: var(--theme--link--color);
                                }
                                &:hover, &:focus {
                                    color: var(--theme--link--color--hover);
                                    .svg-inline--fa {
                                        color: var(--theme--link--color--hover);
                                    }
                                }
                            }
                        }
                    }
                }
                .header--utility-bar--links {
                    display: none;
                }
                .header--utility-bar--tools {
                    ul {
                        margin: 0;
                        display: flex;
                        align-items: center;
                        gap: 30px;
                        li {
                            display: block;
                            margin: 0;
                            padding: 0;
                            #header--utility-bar--basket {
                                position: relative;
                                #header--utility-bar--basket--indicator {
                                    position: absolute;
                                    top: -8px;
                                    right: -10px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    min-width: 18px;
                                    min-height: 18px;
                                    padding: 1px;
                                    border-radius: 50%;
                                    font-size: 12px;
                                    line-height: 1;
                                    background-color: $brand--vivid-pink;
                                    color: white;
                                    #header--utility-bar--basket--indicator--number {
                                        transform: translateY(0.02em);
                                    }
                                }
                            }
                        }
                    }
                }
                @include media-breakpoint-up(md) {
                    .header--utility-bar--links {
                        display: block;
                        ul {
                            margin: 0;
                            display: flex;
                            align-items: center;
                            gap: 15px;
                            li {
                                display: block;
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }

        .header--content {
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: var(--header--height);
            pointer-events: all;

            .header--logo {
                display: block;
                position: absolute;
                top: var(--top-utility-bar--height);
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                justify-content: center;
                align-items: center;
                width: var(--logo-width);
                margin-top: calc(-1 * var(--logo-breakout-top));
                margin-bottom: calc(-1 * var(--logo-breakout-bottom));
                padding: var(--logo-breakout-top) 0 var(--logo-breakout-bottom);
                height: calc(var(--header--height) + var(--logo-breakout-top) + var(--logo-breakout-bottom));
                pointer-events: none;
                .header--logo--decor {
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    user-select: none;
                    background: transparent url("../../img/header-illustration-v2.png") center center no-repeat;
                    background-image: image-set(
                    url("../../img/header-illustration-v2.webp") 1x,
                    url("../../img/header-illustration-v2.png") 1x
                    );
                    background-size: contain;
                }
                a img {
                    position: relative;
                    z-index: 2;
                    width: var(--logo-inner-width);
                    height: auto;
                    pointer-events: all;
                }
                &.animated {
                    .header--logo--decor {
                        will-change: transform;
                        animation-name: header-logo-decor-reveal;
                        animation-duration: 2s;
                        animation-iteration-count: 1;
                    }
                }
            }

            .header--content--inner {

                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .header--cta {
                    flex: 0 1 auto;
                }

                .header--mobile-menu-toggle {
                    flex: 0 1 auto;
                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                        padding: 0;
                        border: 0;
                        .bars {
                            display: block;
                            width: 26px;
                            height: 18px;
                            position: relative;
                            transition: transform 200ms ease-in-out;
                            will-change: transform;
                            i {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 26px;
                                height: 3px;
                                border-radius: 1px;
                                background-color: var(--theme--link--color);
                                transition: transform 200ms ease-in-out, width 200ms ease-in-out, opacity 200ms ease-in-out;
                                will-change: transform;
                                &:nth-child(2) {
                                    top: 8px;
                                }
                                &:nth-child(3) {
                                    top: 16px;
                                }
                            }
                        }
                        &:hover, &:focus {
                            .bars i {
                                background-color: var(--theme--link--color--hover);
                            }
                        }
                        &[aria-expanded="true"] {
                            .bars {
                                transform: rotate(-90deg) translate3d(0,0,0);
                                i {
                                    &:nth-child(1) {
                                        transform: translate3d(0,8px,0) rotateZ(45deg);
                                    }
                                    &:nth-child(2) {
                                        transform: scaleX(0);
                                    }
                                    &:nth-child(3) {
                                        transform: translate3d(0,-8px,0) rotateZ(-45deg);
                                    }
                                }
                            }
                        }
                    }
                }

            }

        }

        #header--search-wrapper {
            --theme--link--color: #{$link-color};
            --theme--link--color--hover: white;
            display: none;
            pointer-events: none;
            &[aria-hidden="false"] {
                display: flex;
                background-color: $brand--moss;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                width: 100%;
                height: calc(var(--top-utility-bar--height) + var(--header--height));
                text-align: center;
                opacity: 0;
                transition: opacity 200ms linear;
                &.search-form--active {
                    opacity: 1;
                    pointer-events: all;
                }
                #header--search-form {
                    display: flex;
                    margin: 0 auto;
                    align-items: center;
                    gap: 10px;
                    height: calc(var(--top-utility-bar--height) + var(--header--height));
                    padding: 0 15px;
                    max-width: 400px;
                    input[type="search"] {
                        flex: 1 1 auto;
                    }
                    button[type="submit"] {
                        flex: 0 0 auto;
                    }
                }
                #header--search-close-btn {
                    position: absolute;
                    top: 0;
                    right: 10px;
                    display: flex;
                    width: var(--btn-width);
                    height: var(--top-utility-bar--height);
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    color: white;
                    opacity: 0.8;
                    &:hover, &:focus {
                        color: white;
                        opacity: 1;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
    
            #header--search-wrapper[aria-hidden="false"] {
                #header--search-close-btn {
                    right: 25px;
                }
            }
            
        }

        @include media-breakpoint-up(lg) {
            .header--content {
                --nav-spacing: 20px;
                .header--content--inner {
                    gap: var(--nav-spacing);
                    .header--cta {
                        flex: 0 1 auto;
                    }
        
                    .header--mobile-menu-toggle {
                        display: none;
                    }

                }

            }
        }

        @include media-breakpoint-up(xl) {
            .header--content {
                --nav-spacing: 30px;
            }
        }

    }

}








#header-wrapper.checkout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 10;

    header {
        background: $brand--white;
        height: var(--header--height);
        box-shadow: 0 1px 6px rgba(black,0.25);
    }

    #skip-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: var(--header--height);
        background: $brand--white;
        font-size: 1rem;
        line-height: 1rem;
        text-decoration: underline;
        opacity: 0;
        pointer-events: none;
        transition: opacity 200ms linear;
        &:focus {
            opacity: 1;
            pointer-events: all;
        }
    }

    .header--content {
        --logo-width: 160px;
        --logo-breakout-top: 20px;
        --logo-breakout-bottom: 5px;
        --logo-inner-width: 100px;
        display: flex;
        gap: 20px;
        width: 100%;
        height: var(--header--height);
        justify-content: center;
        align-items: center;

        .header--left-content, .header--right-content {
            display: flex;
            gap: 10px 20px;
            flex-wrap: wrap;
            width: calc((100% - (var(--logo-width) + 40px)) /2);
            align-items: center;
            justify-content: center;
            font-family: $alt-headings-font-family;
            font-weight: $alt-headings-font-weight;
            line-height: $alt-headings-line-height;
            a {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                @include media-breakpoint-down(sm) {
                    max-width: 60px;
                    svg.fa-arrow-left {
                        margin-right: 0;
                    }
                }
                span.label {
                    font-size: 12px;
                }
            }
        }
        @include media-breakpoint-up(md) {
            .header--left-content, .header--right-content {
                gap: 30px;
                a span.label {
                    font-size: 16px;
                }
            }
            .header--left-content {
                justify-content: flex-start;
            }
            .header--right-content {
                justify-content: flex-end;
            }
        }

        .header--logo {
            display: block;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: var(--logo-width);
            margin-top: calc(-1 * var(--logo-breakout-top));
            margin-bottom: calc(-1 * var(--logo-breakout-bottom));
            padding: var(--logo-breakout-top) 0 var(--logo-breakout-bottom);
            height: calc(var(--header--height) + var(--logo-breakout-top) + var(--logo-breakout-bottom));
            .header--logo--decor {
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                user-select: none;
                background: transparent url("../../img/header-illustration.png") center center no-repeat;
                background-image: image-set(
                  url("../../img/header-illustration.webp") 1x,
                  url("../../img/header-illustration.png") 1x
                );
                background-size: contain;
            }
            a img {
                position: relative;
                z-index: 2;
                width: var(--logo-inner-width);
                height: auto;
            }
            &.animated {
                .header--logo--decor {
                    will-change: transform;
                    animation-name: header-logo-decor-reveal;
                    animation-duration: 2s;
                    animation-iteration-count: 1;
                }
            }
        }

        .header--basket-link {
            position: relative;
            #header--basket-link--indicator {
                position: absolute;
                top: -12px;
                right: -8px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 18px;
                min-height: 18px;
                padding: 1px;
                border-radius: 50%;
                font-size: 13px !important;
                line-height: 1;
                background-color: $brand--vivid-pink;
                color: $brand--white;
                .header--basket-link--indicator--number {
                    transform: translateY(0.02em);
                }
            }
        }

        @include media-breakpoint-up(md) {
            --logo-breakout-top: 30px;
            --logo-breakout-bottom: 15px;
            --logo-width: 200px;
            --logo-inner-width: 120px;
        }
        @include media-breakpoint-up(xl) {
            --logo-breakout-top: 40px;
            --logo-breakout-bottom: 20px;
            --logo-width: 240px;
            --logo-inner-width: 150px;
        }
    }

}
