.testimonial {
    padding: 0;
    margin: 0;
    text-align: center;
    &--star-rating {
        color: var(--theme--decor--color);
        font-size: 1.4rem;
        svg {
            margin: 0 0.15em;
        }
    }
    &--quote {
        color: var(--theme--color--emphasis);
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: 1.2;
        font-size: 1.4rem;
        @include media-breakpoint-up(md) {
            font-size: 1.6rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 1.7rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 1.8rem;
        }
    }
    footer cite {
        font-style: normal;
        line-height: 1.6;
        svg {
            font-size: 1.2rem;
            margin-right: 0.5em;
        }
    }
}
