:root {

    --vpHeight: 100vh;
    --vpWidth: 100vw;
    --vpInitialHeight: 100vh;

    // ******************************
    // *****  layout variables  *****
    // ******************************

    --top-utility-bar--height: 45px;
    --header--height: 80px;

    --logo-width: 160px;
    --logo-breakout-top: 8px;
    --logo-breakout-bottom: 12px;
    --logo-inner-width: 110px;
    
    --promo-bar--height: #{map-get($promo-bar--height, xs)};
    // loop through each breakpoint
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            --promo-bar--height: #{map-get($promo-bar--height, $breakpoint)};
        }
    }

    --edge-padding: 15px;
    --full-height--adjustment: #{$hero--fullscreen--height-reduction--mobile}; // use to show some content below 'full-height' element;

    @include media-breakpoint-up(sm) {
        --edge-padding: 30px;
    }

    @include media-breakpoint-up(md) {
        --logo-breakout-top: 10px;
        --logo-breakout-bottom: 15px;
        --logo-width: 180px;
        --logo-inner-width: 120px;
    }

    @include media-breakpoint-up(lg) {
        --full-height--adjustment: #{$hero--fullscreen--height-reduction--desktop};
    }

    @include media-breakpoint-up(xl) {
        --header--height: 100px;
        --logo-breakout-top: 12px;
        --logo-breakout-bottom: 20px;
        --logo-width: 200px;
        --logo-inner-width: 150px;
    }
    
    // ***********************************
    // *****  theming css variables  *****
    // ***********************************
    body.site-layout, .module--demo-area {
        // apply default theme rules
        @include on-light-theme-vars;
    }
    .module--demo-area {
        background: var(--theme--background-color);
        color: var(--theme--color);
        padding: 30px 0;
    }

}

// body

body.site-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: var(--theme--background-color);
    scroll-behavior: smooth;
    background-position: center top;
    padding-top: calc(var(--top-utility-bar--height) + var(--header--height)) !important;
    &:not(:has(.header--utility-bar)) {
        padding-top: var(--header--height) !important;
    }

    #main-content {
        flex: 1 1 auto;
    }

    #main-footer {
        flex: 0 0 auto;
    }

    .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        padding-left: var(--edge-padding);
        padding-right: var(--edge-padding);
    }

}

// temporary sizing for font-awesome whilst loading
body:not(.fontawesome-i2svg-complete) {
    i.fa-solid, i.fa-sharp, i.fa-regular, i.fa-thin, i.fa-light, i.fa-duotone, i.fa-brands {
        display: inline-block !important;
        min-width: 1em !important;
        min-height: 1em !important;
        vertical-align: -0.125em !important;
    }
}

// Link colours

a:not(.btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
    color: var(--theme--link--color);
    text-decoration: var(--theme--link--text-decoration);
    &:hover, &:focus {
        color: var(--theme--link--color--hover);
        text-decoration: var(--theme--link--text-decoration--hover);
    }
    main p > &:not([class="*"]) {
        text-decoration: underline;
    }
}

// rules

hr {
    border-color: var(--theme--rule--color);
    opacity: 1;
}

// align center until content wraps - then align left
.center-or-wrap-align-start {
    margin: 0 auto;
    width: fit-content;
    max-width: 100%;
    text-align: start;
}

// max-widths (mostly for form inputs
.mw-100px {
    max-width: 100px;
}
.mw-200px {
    max-width: 200px;
}
.mw-300px {
    max-width: 300px;
}
.mw-400px {
    max-width: 400px;
}
.mw-500px {
    max-width: 500px;
}

// defer-iframe-content
iframe.defer-iframe-content[data-src] {
    opacity: 0;
    transition: opacity 1s linear;
    &.loaded {
        opacity: 1;
    }
}

// fullscreen-top-section
.fullscreen-top-section {
    display: flex;
    flex-direction: column;
    --top-section-min-height: max(#{map-get($hero--img--min-heights, xs)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--full-height--adjustment))));
    .with-promo-bar & {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, xs)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height) + var(--full-height--adjustment))));
    }
    min-height: var(--top-section-min-height);
    @include media-breakpoint-up(sm) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, sm)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, sm)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(md) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, md)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, md)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(lg) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, lg)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, lg)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(xl) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, xl)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, xl)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(xxl) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, xxl)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--full-height--adjustment))));
        .with-promo-bar & {
            --top-section-min-height: max(#{map-get($hero--img--min-heights, xxl)}, calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height) + var(--full-height--adjustment))));
        }
        min-height: var(--top-section-min-height);
    }
}

// emergency message

.emergency-message {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9;
    border: 0;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    box-shadow: 0 1px 6px rgba(black,0.25);
    .fa-exclamation-triangle {
        color: $brand--green;
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

// breadcrumb

.breadcrumb {
    background-color: transparent;
    font-size: 1rem;
    justify-content: center;
    margin-bottom: 0.5rem;
    font-family: $alt-headings-font-family;
    font-weight: $alt-headings-font-weight;
    line-height: $alt-headings-line-height;
    .breadcrumb-item {
        &:not(:first-child):before {
            content: '';
            display: inline-block;
            vertical-align: baseline;
            height: 100%;
            width: 1em;
            background: no-repeat center url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512' class='svg-inline--fa fa-angle-right fa-w-6'%3E%3Cpath fill='%239daa80' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z' class=''%3E%3C/path%3E%3C/svg%3E");
            background-size: auto 80%;
            background-position: bottom left;
        }
        a {
            color: var(--theme--color);
            &:hover, &:focus {
                color: var(--theme--link--color--hover);
                text-decoration: underline;
            }
        }
    }
}

// sticky content
.sticky-content {
    position: sticky;
    top: 30px;
}

// decor

.leaf-decor {
    display: block;
    margin: 0 auto;
    width: 42px;
    height: 29px;
    background: transparent url('../../img/leaf-decor.svg') center center no-repeat;
}


// full-width--breakout (make content that has to be inside a column but needs to be full width)

.full-width-breakout {
    display: block;
    width: 100%;
    &--inner {
        display: block;
        position: relative;
        width: var(--vpWidth);
        margin: 0 0 0 calc((var(--vpWidth) - 100%) * -.5) !important;
    }
}

// wide-image-text-layout

.wide-image-text-layout {
    --img-max-width: 300px;
    display: block;
    .wide-image-text-layout--image {
        display: block;
        margin-bottom: 15px;
    }
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        gap: var(--panel--padding);
        align-items: stretch;
        .wide-image-text-layout--image {
            position: relative;
            flex: 0 0 auto;
            min-height: 100%;
            width: 50%;
            max-width: var(--img-max-width);
            margin: 0;
            &:before { // minimum ratio 16:9
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 56.25%;
            }
            .img-scale-wrapper, img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .wide-image-text-layout--content {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            min-height: 100%;
            justify-content: center;
            align-items: flex-start;
            .wide-image-text-layout--content--inner {
                width: 100%;
                & > *:last-child {
                    margin-bottom: 0;
                }
                .panel--cta {
                    margin-bottom: 0;
                }
                ul.taglist {
                    margin: 0 0 10px;
                    justify-content: flex-end;
                    @include media-breakpoint-up(md) {
                        float: right;
                        margin: 0 0 15px 15px;
                        max-width: 50%;
                    }
                    @include media-breakpoint-up(lg) {
                        max-width: 33%;
                    }
                    @include media-breakpoint-up(xl) {
                        max-width: 25%;
                    }
                }
            }

        }
        // image right
        &.wide-image-text-layout--image-right {
            .wide-image-text-layout--image {
                order: 2;
            }
            .wide-image-text-layout--content {
                order: 1;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        --img-max-width: 400px;
    }
    // opening times modifiers
    .opening-times & {
        --img-max-width: 200px;
        @include media-breakpoint-up(xl) {
            --img-max-width: 300px;
        }
    }
}

// 50/50 layout with optional carousel

.split-img-content-layout {
    display: flex;
    flex-direction: column;
    .split-img-content-layout--img {
        background-color: var(--dark);
        width: 100%;
    }
    .split-img-content-layout--content {
        width: 100%;
        .split-img-content-layout--content--inner {
            .container { // set max-widths to align with other page elements
                max-width: none;
            }
        }
    }
    @include media-breakpoint-up(md) {
        .split-img-content-layout--content {
            .split-img-content-layout--content--inner {
                .container {
                    max-width: 83%;
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: stretch;
        .split-img-content-layout--img {
            position: relative;
            width: 50%;
            min-height: 100%;
            overflow: hidden;
            &:before { /* used to set the minimum section height to 4:3 image height */
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 75%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: calc(100% + 1px);
                object-fit: cover;
                font-family: 'object-fit: cover';
            }
            .carousel {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .carousel-inner, .carousel-item {
                    height: 100%;
                    overflow: hidden;
                }
            }
        }
        .split-img-content-layout--content {
            display: flex;
            width: 50%;
            min-height: 100%;
            align-items: center;
            .split-img-content-layout--content--inner {
                width: 100%;
                .container { // set max-widths to align with other page elements
                    margin-left: #{$split-img-content--content--margin--lg};
                    margin-right: auto;
                    max-width: none;
                }
            }
        }

        &.split-img-content-layout--img-right {
            flex-direction: row-reverse;
            .split-img-content-layout--content {
                .container { // set max-widths to align with other page elements
                    margin-left: auto;
                    margin-right: #{$split-img-content--content--margin--lg};
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {
        .split-img-content-layout--content {
            .split-img-content-layout--content--inner {
                .container {
                    margin-left: #{$split-img-content--content--margin--xl};
                }
            }
        }
        &.split-img-content-layout--img-right {
            .split-img-content-layout--content {
                .container {
                    margin-left: auto;
                    margin-right: #{$split-img-content--content--margin--xl};
                }
            }
        }
    }
    @include media-breakpoint-up(xxl) {
        .split-img-content-layout--content {
            .split-img-content-layout--content--inner {
                .container {
                    margin-left: #{$split-img-content--content--margin--xxl};
                    max-width: calc((#{map-get($container-max-widths, xxl)} / 2) - #{$split-img-content--content--margin--xxl});
                }
            }
        }
        &.split-img-content-layout--img-right {
            .split-img-content-layout--content {
                .container {
                    margin-left: auto;
                    margin-right: #{$split-img-content--content--margin--xxl};
                }
            }
        }
    }
    &.split-img-content-layout--hero { // change min-heights to match standard hero elements.split-img-content-layout--img {
        .split-img-content-layout--img:before {
            padding-bottom: #{map-get($hero--img--min-heights, xs)};
            // loop through each breakpoint
            @each $breakpoint in map-keys($grid-breakpoints) {
                @include media-breakpoint-up($breakpoint) {
                    padding-bottom: #{map-get($hero--img--min-heights, $breakpoint)};
                }
            }
        }
    }
}

// 100vh bg image section

.full-screen-image-bg-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: $dark;
    will-change: transform;
    &--img {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        user-select: none;
        &:after {
            display: block;
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(black, 0.5); // up for grabs
        }
        img {
            display: block;
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &--content {
        display: flex;
        min-height: var(--vpHeight);
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        opacity: 0;
        will-change: opacity;
        transition: opacity 500ms linear;
        transition-delay: 1s;
    }
    &.content-align-top {
        align-items: flex-start;
    }
    &.content-align-bottom {
        align-items: flex-end;
    }
    &.hunt-in .full-screen-image-bg-section--content {
        opacity: 1;
    }
    &--sticky {
        @supports(position: sticky) {
            .full-screen-image-bg-section--img {
                img {
                    position: sticky;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: var(--vpHeight);
                }
            }
        }
    }
}

// split-image-bg-sections 

.split-image-bg-sections {
    --gap: 30px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    .split-image-bg-section {
        display: flex;
        position: relative;
        width: 100%;
        min-height: var(--vpWidth);
        justify-content: center;
        align-items: center;
        padding: var(--edge-padding);
        &--img {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            aspect-ratio: none;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--content {
            position: relative;
            z-index: 2;
            width: min(500px, 100%);
            opacity: 0;
            transform: scale(0.9);
            transform-origin: center center;
            will-change: transform;
            transition: opacity 500ms linear, transform 1s ease-out;
            transition-delay: 1s;
        }
        &.hunt-in .split-image-bg-section--content {
            opacity: 1;
            transform: scale(1);
        }
    }
    @include media-breakpoint-up(sm) {
        .split-image-bg-section {
            min-height: calc(var(--vpWidth) * .75);
        }
    }
    @include media-breakpoint-up(md) {
        .split-image-bg-section {
            min-height: calc(var(--vpWidth) * .5625);
        }
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        min-height: calc((var(--vpWidth) * .5625) / 2);
        .split-image-bg-section {
            width: calc(50% - 15px);
            min-height: 100%;
            justify-content: flex-start;
            &--content {
                width: min(500px, 75%);
                transform-origin: left center;
            }
            &:nth-child(2) {
                justify-content: flex-end;
                .split-image-bg-section--content {
                    transform-origin: right center;
                }
            }
        }
    }
}

// Panel behind widescreen (min-ratio) media element

.panel-img-overlap {
    --img-width: 50%;
    --content-width: 50%;
    --overlap: 120px;
    @include media-breakpoint-up(lg) {
        .panel-img-overlap--inner {
            display: flex;
            width: 100%;
            align-items: center;
            .panel-img-overlap--visual {
                position: relative;
                z-index: 2;
                width: calc(var(--img-width) + var(--overlap));
                .panel-img-overlap--visual--inner {
                    background-color: $dark;
                }
            }
            .panel-img-overlap--content {
                position: relative;
                z-index: 1;
                width: var(--content-width);
                align-self: stretch;
                .panel {
                    display: flex;
                    flex-direction: column;
                    min-height: 100%;
                    justify-content: center;
                }
            }
        }
        &:not(.panel-img-overlap--img-left) {
            .panel-img-overlap--visual {
                order: 2;
                margin-left: calc(-1 * var(--overlap));
            }
            .panel-img-overlap--content {
                order: 1;
                .panel {
                    padding-right: calc(var(--overlap) + 30px) !important;
                }
            }
        }
        &--img-left {
            .panel-img-overlap--visual {
                order: 1;
                margin-right: calc(-1 * var(--overlap));
            }
            .panel-img-overlap--content {
                order: 2;
                .panel {
                    padding-left: calc(var(--overlap) + 30px) !important;
                }
            }
        }
    }
}

// Widescreen img - content panel overlap -->

.widecreen-image-panel-overlap {
    --img-width: 640px;
    --content-width: 376px;
    &--img {
        background-color: $dark;
        img {
            aspect-ratio: 16/9;
        }
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        min-height: calc(var(--img-width) * .5625);
        padding: 48px 0;
        &--img {
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: var(--img-width);
            height: 100%;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--content {
            position: relative;
            z-index: 2;
            width: var(--content-width);
        }
        &.widecreen-image-panel-overlap--img-right {
            justify-content: flex-start;
            .widecreen-image-panel-overlap--img {
                left: auto;
                right: 0;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        --img-width: 736px;
        --content-width: 451px;
    }
    @include media-breakpoint-up(xxl) {
        --img-width: 856px;
        --content-width: 526px;
    }
}
