@keyframes video-loading-sprite {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-20px);
    }
}

.hero {
    display: block;
    position: relative;
    background-color: $brand--green;
    --img--min-height: #{map-get($hero--img--min-heights, xs)};
    --img--max-height: #{map-get($hero--img--max-heights, xs)};
    --img--height: #{map-get($hero--img--heights, xs)};
    // loop through each breakpoint
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            --img--min-height: #{map-get($hero--img--min-heights, $breakpoint)};
            --img--max-height: #{map-get($hero--img--max-heights, $breakpoint)};
            --img--height: #{map-get($hero--img--heights, $breakpoint)};
        }
    }
    .hero--layout {
        display: contents;
    }
    .hero--image {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        min-height: var(--img--min-height);
        max-height: var(--img--max-height);
        height: var(--img--height);
        overflow: hidden;
        background-color: $dark;
        overflow: hidden;
        picture {
            width: 100%;
        }
        img {
            display: block;
            width: 100%;
            height: calc(100% + 1px);
            object-fit: cover;
        }
    }
    .hero--content {
        width: 100%;
    }

    .carousel {
        width: 100%;
        .carousel-controls {
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            min-height: var(--img--min-height);
            max-height: var(--img--max-height);
            height: var(--img--height);
            pointer-events: none;
            .carousel-indicators {
                pointer-events: all;
            }
        }
    }

    // shallow version
    &.hero--shallow {
        width: 100%;
        height: 150px;
        --img--min-height: 150px;
        --img--max-height: 150px;
        --img--height: #{map-get($hero--img--heights, xs)};
        @include media-breakpoint-up(md) {
            height: 250px;
            --img--min-height: 250px;
            --img--max-height: 250px;
        }
        @include media-breakpoint-up(lg) {
            height: 350px;
            --img--min-height: 350px;
            --img--max-height: 350px;
        }
    }

    // Hero video stuff

    .hero--layout .hero--video {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        min-height: var(--img--min-height);
        max-height: var(--img--max-height);
        height: var(--img--height);
        overflow: hidden;
        &.vimeo > iframe { // bug fix for vimeo embed
            display: none;
        }
        .hero--video-inner { // should remain as 16:9 ratio container and resize to emulate object-fit: cover
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            --transition: opacity 1s linear;
            .hero--poster-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
                opacity: 1;
                transition: var(--transition);
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0;
                    transition: opacity 500ms linear;
                    &.loaded {
                        opacity: 1;
                    }
                }
            }
            iframe {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: var(--transition);
            }
            &.reveal {
                .hero--poster-image {
                    opacity: 0;
                }
                iframe {
                    opacity: 1;
                }
            }
            &.has-poster {
                --transition: none;
                iframe {
                    opacity: 1;
                }
                &.crossfade-poster {
                    --transition: opacity 1s linear;
                }
            }
        }
        .hero--video-loading-sprite {
            position: absolute;
            z-index: 4;
            top: 15px;
            left: 15px;
            width: 60px;
            height: 3px;
            overflow: hidden;
            opacity: 1;
            will-change: opacity;
            &:before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: calc(100% + 30px);
                height: 100%;
                background: linear-gradient(to right, rgba(black, 0.4) 0%, rgba(black, 0.4) 50%, white 50%, white 100%) repeat-x;
                background-size: 20px 100%;
                will-change: transform;
                animation: video-loading-sprite 1s infinite linear;
            }
            &.hidden {
                opacity: 0;
                transition: opacity 500ms linear;
            }
        }
        .hero--video--control {
            display: flex;
            flex-direction: row;
            gap: 4px;
            position: absolute;
            z-index: 4;
            top: 15px;
            right: 15px;
            opacity: 0;
            transition: opacity 500ms linear;
            pointer-events: none;
            &.reveal {
                opacity: 1;
                pointer-events: all;
            }
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                appearance: none;
                width: 30px;
                height: 30px;
                font-size: 14px;
                line-height: 14px;
                margin: 0;
                padding: 0;
                border: 0;
                background-color: rgba(black, .4);
                color: white;
                cursor: pointer;
                &:not(:disabled):focus, &:not(:disabled):hover {
                    background-color: $dark;
                    color: white;
                }
                .hero--video--control--play, .hero--video--control--pause, .hero--video--control--sound-on, .hero--video--control--sound-off {
                    display: inline;
                    &[aria-hidden="true"] {
                        display: none;
                    }
                }
            }
        }
    }

    // when inside fullscreen-top-section
    
    .fullscreen-top-section & {
        display: flex;
        flex: 1 1 auto;
        min-height: var(--img--min-height);
        height: 100%;
        align-items: stretch;

        & > .hero--layout { // not carousels
            display: flex;
            flex-wrap: wrap;
            min-height: 100%;
            align-items: stretch;
            width: 100%;
            .hero--image, .hero--video {
                min-height: var(--img--min-height);
                max-height: var(--img--max-height);
                height: var(--img--height);
            }
        }

        .carousel .hero--image {
            min-height: var(--img--min-height);
            max-height: var(--img--max-height);
            height: var(--img--height);
        }

        .hero--layout .hero--content .hero--content--inner .hero--panel .hero--panel--inner {
            padding: var(--edge-padding);
            & > *:last-child {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            .carousel {
                .carousel-controls {
                    min-height: 0;
                    max-height: none;
                    height: 100%;
                }
                .carousel-inner, .carousel-item {
                    width: 100%;
                    height: 100%;
                }
            }

            .hero--layout {
                .hero--content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    z-index: 3;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 30px;
                    pointer-events: none;
                    &.content-top {
                        align-items: flex-start;
                    }
                    &.content-bottom {
                        align-items: flex-end;
                    }
                    .hero--content--inner > * {
                        pointer-events: all;
                    }
                }
                .hero--image, .hero--video {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    max-height: none;
                }
                .hero--tint {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    background-color: black;
                    pointer-events: none;
                    opacity: 0;
                    &.tint-90 {
                        opacity: .9;
                    }
                    &.tint-80 {
                        opacity: .8;
                    }
                    &.tint-70 {
                        opacity: .7;
                    }
                    &.tint-60 {
                        opacity: .6;
                    }
                    &.tint-50 {
                        opacity: .5;
                    }
                    &.tint-40 {
                        opacity: .4;
                    }
                    &.tint-30 {
                        opacity: .3;
                    }
                    &.tint-20 {
                        opacity: .2;
                    }
                    &.tint-10 {
                        opacity: .1;
                    }
                }
                &.content-left, &.content-right {
                    --panel--edge-space: var(--edge-padding);
                    --panel--inner-width: calc((var(--vpWidth) - (2 * (var(--edge-padding))) - 3rem) / 3); // 4 grid col width
                    @include media-breakpoint-up(xxl) {
                        --panel--edge-space: calc((100% - 1260px) / 2);
                        --panel--inner-width: 404px;
                    }
                    .hero--image {
                        width: calc(100% - (var(--panel--edge-space) + var(--panel--inner-width)));
                    }
                    .hero--content {
                        width: calc(var(--panel--edge-space) + var(--panel--inner-width));
                        height: 100%;
                        padding: 0;
                        .hero--content--inner {
                            width: 100%;
                            height: 100%;
                            .hero--panel {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                                padding: 0;
                                .hero--panel--inner {
                                    width: var(--panel--inner-width);
                                    max-height: 100%;
                                    overflow-y: auto;
                                }
                            }
                        }
                    }
                }
                &.content-left {
                    .hero--image {
                        left: auto;
                        right: 0;
                    }
                    .hero--content .hero--panel {
                        justify-content: flex-end;
                        .hero--panel--inner {
                            padding-left: 0;
                        }
                    }
                }
                &.content-right {
                    .hero--content {
                        left: auto;
                        right: 0;
                        .hero--panel {
                            justify-content: flex-start;
                            .hero--panel--inner {
                                padding-right: 0;
                            }
                        }
                    }
                }
                &.clickable.enabled {
                    cursor: pointer;
                }
            }

            .carousel {
                height: auto;
                width: 100%;
            }
        }

    }
}
