.header--menu {

    // mobile version

    @include media-breakpoint-down(lg) {

        display: none;
        opacity: 0;
        pointer-events: hidden;
        position: absolute;
        z-index: -1;
        top: calc(var(--top-utility-bar--height) + var(--header--height));
        left: 0;
        width: 100%;
        padding: 20px 0;
        height: calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height)));
        overflow-y: auto;
        background-color: $color--lightest-green;
        will-change: opacity;

        &[aria-hidden="false"] {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            opacity: 0;
        }
        .mobile-menu-active & {
            opacity: 1;
            pointer-events: all;
        }
        .mobile-menu-fading & {
            transition: opacity 300ms linear;
        }

        nav.header--menu--main-nav {
            --theme--link--color: #{$link-color};
            --theme--link--color--hover: #{$link-hover-color};
            margin: 0 auto;
            width: calc(var(--vpWidth) - 60px);
            max-width: 400px;
            & > ul {
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                @include media-breakpoint-up(md) {
                    max-width: 400px;
                }
        
                & > li {
                    display: block;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    border-bottom: 1px solid white;

                    & > a, & > button {
                        appearance: none;
                        display: block;
                        width: 100%;
                        margin: 0;
                        padding: 15px 0;
                        border: 0;
                        text-align: left;
                        font-family: $alt-headings-font-family;
                        font-weight: $alt-headings-font-weight;
                        text-decoration: none;
                        font-size: 1.4rem;
                        line-height: 1.1;
                        background-color: transparent;
                        color: var(--theme--link--color);
                        
                        &:hover, &:focus {
                            color: var(--theme--link--color--hover);
                        }
                    }
                }

                button {
                    position: relative;
        
                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 18px;
                        right: 0;
                        width: 18px;
                        height: 18px;
                        background-color: $brand--moss;
                        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z'/%3E%3C/svg%3E");
                        mask-size: 100%;
                        mask-repeat: no-repeat;
                        transform: scale(0.84);
                        will-change: transform;
                        transition: transform 300ms ease-in-out;
                    }

                    &:hover, &:focus {
                        &:before {
                            background-color: var(--theme--link--color--hover);
                        }
                    }
        
                    &[aria-expanded="true"] {
                        color: var(--theme--link--color--hover);
                        &:before {
                            transform: scale(1) rotate(135deg);
                        }
                    }
                }

                /* submenu level */
        
                .submenu {
                    --theme--link--color: #{$link-color};
                    --theme--link--color--hover: #{$link-hover-color};
                    overflow: hidden;
                    max-height: 0;
                    transition: max-height 300ms ease-in-out;
                    .submenu--canonical-link {
                        margin: 0 0 20px;
                        padding-top: 10px;
                        a {
                            font-family: $font-family-base;
                            font-size: 1rem;
                            line-height: 1.2;
                            svg {
                                font-size: 16px;
                            }
                        }
                    }
                    .submenu--content-area--section {
                        margin: 0;
                        padding: 0 0 20px;
                        h3 {
                            font-family: $alt-headings-font-family;
                            font-weight: $alt-headings-font-weight;
                            font-size: 1rem;
                            color: $brand--moss;
                            
                        }
                        ul {
                            display: block;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            li {
                                display: block;
                                width: 100%;
                                margin: 0 0 10px;
                                padding: 0;
                                list-style: none;
                                a, button {
                                    font-family: $font-family-base;
                                    font-size: 1rem;
                                    line-height: 1.2;
                                    text-transform: none;
                                }
                            }
                        }
                    }
                }
            }
            .mobile--utility-links {
                margin-top: 30px;
                --theme--link--color: #{$link-color};
                --theme--link--color--hover: #{$link-hover-color};
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li {
                        display: block;
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        font-size: 0.875rem;
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }

    }

    // desktop version

    @include media-breakpoint-up(lg) {
        display: block;
        flex: 1 0 auto;

        nav.header--menu--main-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            & > ul {
                display: flex;
                gap: var(--nav-spacing);
                align-items: center;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                &.main-menu--left-side {
                    justify-content: flex-start;
                }
                &.main-menu--right-side {
                    justify-content: flex-end;
                }
        
                & > li {
                    display: block;
                    flex: 0 0 auto;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    list-style: none;
                    text-align: center;

                    & > a, & > button {
                        appearance: none;
                        display: inline-block;
                        position: relative;
                        margin: 0;
                        padding: 15px 2px;
                        border: 0;
                        text-align: center;
                        font-family: $alt-headings-font-family;
                        font-weight: $alt-headings-font-weight;
                        text-decoration: none;
                        font-size: 1.2rem;
                        line-height: 1;
                        background-color: transparent;
                        color: var(--theme--link--color);
                        @include media-breakpoint-up(xl) {
                            font-size: 1.3rem;
                        }
                        @include media-breakpoint-up(xxl) {
                            font-size: 1.4rem;
                        }
                        &:after {
                            display: block;
                            content: '';
                            position: absolute;
                            bottom: 10px;
                            left: 2px;
                            width: calc(100% - 4px);
                            height: 2px;
                            background-color: var(--theme--link--color);
                            opacity: 0;
                            transform-origin: 0 0;
                            transform: scaleX(0);
                            transition: opacity 300ms linear, transform 300ms ease-in;
                        }
                        &:hover, &:not([aria-expanded="true"]):focus {
                            color: var(--theme--link--color--hover);
                            &:after {
                                background-color: var(--theme--link--color--hover);
                                opacity: 1;
                                transform: scaleX(1);
                                transition: background-color 200ms linear, opacity 100ms linear, transform 300ms ease-out;
                            }
                        }
                        &[aria-expanded="true"] {
                            color: var(--theme--link--color--hover);
                        }
                    }

                    .submenu {

                        --theme--link--color: #{$dark};
                        --theme--link--color--hover: #{$link-hover-color};

                        display: none;
                        pointer-events: hidden;
                        position: absolute;
                        z-index: -1;
                        top: calc(var(--top-utility-bar--height) + var(--header--height));
                        left: 0;
                        width: 100%;
                        background-color: $color--lightest-green;
                        box-shadow: 0 3px 4px rgba(black, 0.15);
                        opacity: 0;
                        will-change: opacity;
                        text-align: left;

                        &[aria-hidden="false"] {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            opacity: 0;
                        }
                        .desktop-menu-active & {
                            opacity: 1;
                            pointer-events: all;
                        }
                        .desktop-menu-fading & {
                            transition: opacity 200ms linear;
                        }

                        .submenu--content-area {
                            --desktop--submenu-content--max-width: 100%;
                            position: relative;
                            width: 100%;
                            padding: 40px 0;
                            max-height: calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height)));
                            overflow-y: auto;
                            @include media-breakpoint-up(xl) {
                                --desktop--submenu-content--max-width: #{map-get($container-max-widths, "xl")};
                            }
                            @include media-breakpoint-up(xxl) {
                                --desktop--submenu-content--max-width: #{map-get($container-max-widths, "xxl")};
                            }

                            .submenu--canonical-link {
                                margin: 0 auto 30px;
                                padding: 0 var(--edge-padding);
                                max-width: var(--desktop--submenu-content--max-width);
                                a {
                                    font-family: $font-family-base;
                                    font-weight: $font-weight-bold;
                                    font-size: 18px;
                                    svg {
                                        font-size: 16px;
                                    }
                                }
                            }

                            .submenu--content-area--sections {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                align-items: stretch;
                                gap: 20px 30px;
                                margin: 0 auto;
                                padding: 0 var(--edge-padding);
                                width: 100%;
                                max-width: var(--desktop--submenu-content--max-width);

                                .submenu--content-area--section {
                                    flex: 0 1 auto;
                                    width: calc((100% / 3) - 60px);
                                    h3 {
                                        font-family: $alt-headings-font-family;
                                        font-weight: $alt-headings-font-weight;
                                        font-size: 1.2rem;
                                        color: $brand--moss;
                                        
                                    }

                                    ul {
                                        margin: 0;
                                        padding: 0;
                                        list-style: none;

                                        li {
                                            margin: 0 0 8px;
                                            padding: 0;
                                            list-style: none;

                                            a {
                                                font-size: 16px;
                                            }
                                        }

                                    }
                                    
                                }

                            }

                            .submenu--close {
                                position: absolute;
                                top: 22px;
                                right: var(--edge-padding);
                                color: $brand--green;
                                font-size: 20px;
                                &:hover, &:focus {
                                    color: var(--theme--link--color--hover);
                                }
                            }

                        }
                        
                    }
                }
            }
        }
        .submenu--menu-indicator {
            position: fixed;
            top: calc(var(--top-utility-bar--height) + var(--header--height) - 15px);
            left: 0;
            pointer-events: none;
            width: 30px;
            height: 15px;
            overflow: hidden;
            &:before {
                display: block;
                content: '';
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid $color--lightest-green;
                opacity: 0;
                transform: translate3d(0,15px,0);
                transition: transform 300ms ease-in-out, opacity 200ms linear;
                will-change: transform;
            }
            .desktop-menu-active &:before {
                opacity: 1;
                transform: translate3d(0,0,0);
                transition: transform 300ms ease-in-out, opacity 0s linear;
                transition-delay: 200ms;
            }
        }
    }
}
