#main-footer {
    margin-top: 100px;
    position: relative;
    padding: 90px var(--edge-padding) 30px;
    #main-footer--decor {
        position: absolute;
        z-index: 1;
        top: -50px;
        bottom: 0;
        left: 0;
        width: min(90%, 480px);
        overflow: hidden;
        user-select: none;
        pointer-events: none;
    }
    footer {
        position: relative;
        z-index: 2;
        & > .container {
            @include media-breakpoint-up(xl) {
                padding-left: calc(460px - ((100vw - #{map-get($container-max-widths, "xl")}) / 2));
            }
            @include media-breakpoint-up(xxl) {
                padding-left: calc(460px - ((100vw - #{map-get($container-max-widths, "xxl")}) / 2));
            }

            #main-footer--panel {
                background-color: $brand--white;
                padding: 20px;
                @include media-breakpoint-up(sm) {
                    padding: 30px;
                }
                @include media-breakpoint-up(xl) {
                    background-color: transparent;
                    padding: 0;
                }

                #main-footer--primary-links {
                    ul {
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        gap: 10px;
                        align-items: center;
                        li {
                            margin: 0;
                            text-align: center;
                        }
                        @include media-breakpoint-up(md) {
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 15px 30px;
                            align-items: flex-start;
                            li {
                                text-align: left;
                            }
                        }
                    }
                }
                
                #main-footer--social-links {
                    ul {
                        display: flex;
                        width: 100%;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 15px 20px;
                        justify-content: center;
                        font-size: 1.6rem;
                        @include media-breakpoint-up(md) {
                            justify-content: flex-start;
                            gap: 15px 30px;
                        }
                        li {
                            margin: 0;
                        }
                    }
                }

                #main-footer--logos {
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-evenly;
                        gap: 20px 30px;
                        margin: 0;
                        padding: 0;
                        li {
                            display: contents;
                            img {
                                width: auto;
                                height: 60px;
                            }
                        }
                        @include media-breakpoint-up(md) {
                            justify-content: flex-end;
                            align-items: center;
                        }
                        @include media-breakpoint-up(lg) {
                            gap: 20px 40px;
                            li img {
                                height: 70px;
                            }
                        }
                    }
                }
                
                #main-footer--legal-copy {
                    p {
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

            }

        }
    }
}
