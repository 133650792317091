:root {
    --panel--padding: #{map-get($panel--padding, xs)};
    // loop through each breakpoint
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            --panel--padding: #{map-get($panel--padding, $breakpoint)};
        }
    }
}

.panel-padding {
    padding: var(--panel--padding); // default padding
}

.panel {
    display: block;
    padding: var(--panel--padding); // default padding
    & > *:last-child {
        margin-bottom: 0;
    }
}

.panel--outline {
    border: 1px solid var(--theme--rule--color);
}

.icon-panel {
    .icon-panel--icon {
        color: var(--theme--icon--color);
        font-size: 2rem;
        margin: 0 0 .5rem 0;
    }
}
a.icon-panel {
    transition: background-color 200ms linear, color 200ms linear;
    &:hover, &:focus {
        background-color: var(--theme--btn--primary--bg-color--hover) !important;
        color: var(--theme--btn--primary--color--hover) !important;
        text-decoration: none;
        transition-duration: 100ms, 100ms;
        h1, h2, h3, h4, h5, h6 {
            text-decoration: underline;
            color: var(--theme--btn--primary--color--hover) !important;
        }
        .btn {
            color: var(--theme--btn--primary--color--hover) !important;
        }
    }
}

// ticket panels

.panel.ticket-panel {
    position: relative;
    .panel--top-heading {
        padding-bottom: 1rem;
        h3 {
            margin: 0;
        }
        &.panel--top-heading--with-roundel {
            --roundel--top: -5px;
            --roundel--right: -5px;
            --roundel--diameter: 80px;
            h3 {
                padding-right: calc(5px + var(--roundel--diameter) - var(--panel--padding) + var(--roundel--right));
            }
            .panel--top-roundel {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                position: absolute;
                z-index: 2;
                top: var(--roundel--top);
                right: var(--roundel--right);
                width: var(--roundel--diameter);
                height: var(--roundel--diameter);
                margin: 0;
                padding: 0;
                border-radius: 50%;
                background-color: $brand--vivid-pink;
                color: $brand--white;
                font-size: 1rem;
                font-family: $alt-headings-font-family;
                font-weight: $alt-headings-font-weight;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }
    }
    .panel--top-image {
        margin: 0 calc(-1 * var(--panel--padding));
    }
    .panel--strapline {
        margin: 0 calc(-1 *var(--panel--padding));
        padding: 5px 10px;
        text-align: center;
        background-color: var(--theme--color);
        color: var(--theme--color--emphasis--invert);
        text-transform: uppercase;
        font-family: $alt-headings-font-family;
        font-weight: $alt-headings-font-weight;
        font-size: 1.2rem;
        line-height: 1.2;
    }
    .tick-list {
        margin: 20px 0 0;
    }
    p[data-hm-section="panel-cta"] {
        margin: 20px 0 0;
    }
}

.map-panel {
    width: 100%;

    .map-mask {
        position: relative;
        width: 100%;
        height: 0;
        padding: 0 0 100%;
        overflow: hidden;
        z-index: 0;

        @include media-breakpoint-up(md) {
            padding-bottom: 56.25%;
        }

        iframe, picture, img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: none;
            z-index: 0;
            border: 0;
        }
    }
}
